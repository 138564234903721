import { NativeModules, Platform } from 'react-native';
import { hasNotch, getVersion, getBundleId, getDeviceType, hasDynamicIsland, } from 'utils/packages/rnDeviceInfo';
// please keep this list sorted
export const featureFlags = [
    'after_24hrs_promotion',
    'automated_savings_v2',
    'blinkist_affordability_check',
    'blinkist_warn_on_skip',
    'budgets_in_onboarding_before_choose_bank',
    'connections_limit_one_on_free',
    'energy_switch_two',
    'flow_to_refund',
    'in_app_rent_question',
    'invest_educational_videos',
    'investment_providers',
    'kyc_error_message',
    'min_deposit',
    'monevo_api',
    'monevo_us_api',
    'new_budgeting_funnel',
    'new_referrals',
    'no_waiting',
    'new_referrals',
    'onboarding_address',
    'onboarding_kyc_v2',
    'pre_approved_transfers',
    'premium_free_welcome_screen_2',
    'premium_money_back_guarantee_v2',
    'premium_on_walkthrough_ended',
    'premium_start_7_day_trial_button_text',
    'premium_web',
    'premium_welcome_screen_with_trial',
    'referrals_get_button_feed',
    'rent_reporting_two',
    'reverse_trial',
    'show_create_credit_builder_card',
    'subscriptions_in_feed',
    'subscription_via_stripe_ios',
    'subscription_via_stripe_android',
    'subscription_via_stripe_card_fallback',
    'terms',
    'us_investments',
    'walkthrough_v5',
    'web_qr_code_scanner_in_settings',
    'welcome_back_screen',
];
export const defaultCategories = [
    'shopping',
    'transport',
    'groceries',
    'entertainment',
    'cash',
    'eatingout',
    'holidays',
    'health',
    'housing',
    'bills',
    'charity',
    'investments',
    'education',
    'family',
    'business',
    'income',
    'general',
    'internal',
    'custom1',
];
export const bundleId = getBundleId();
// used for Siri shortcuts
export const transactionsActivityType = `${bundleId}.AllTransactions`;
export const subscriptionsActivityType = `${bundleId}.Subscriptions`;
// Flag used for checking if the user has rated
export const HAS_RATED_APP_FLAG = 'HAS_RATED_APP_FLAG';
// Flag used for checking the last time the user was asked to rate the app
export const LAST_RATE_TIME = 'LAST_RATE_TIME';
export const appVersion = getVersion();
export const isRunningTestflight = !!NativeModules.BuildEnv?.isTestflight;
export const isIOS = Platform.OS === 'ios';
export const isWeb = Platform.OS === 'web';
export const isAndroid = Platform.OS === 'android';
export const isDesktop = getDeviceType() === 'Desktop';
export const keyboardAvoidingViewBehavior = isIOS ? 'padding' : undefined;
export const isDesktopOrWeb = isDesktop || isWeb;
export const emptyArray = [];
export const deviceHasNotch = hasNotch();
export const deviceHasDynamicIsland = hasDynamicIsland();
