import moment from 'moment';
import qs from 'query-string';
import { featureFlags } from '../constants';
import { CALL_API } from '../middleware/api';
import * as types from './types';
export const setCurrentRouteName = (data) => ({
    type: types.SET_CURRENT_ROUTE_NAME,
    payload: data,
});
export const setUnhandledNavigateAction = (data) => ({
    type: types.SET_UNHANDLED_NAVIGATE_ACTION,
    payload: data,
});
export const getFeatureFlags = () => ({
    [CALL_API]: {
        endpoint: `/feature-flags/?${qs
            .stringify({ flags: featureFlags })
            .replace(/flags=/g, 'flags[]=')}`,
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.GET_FEATURE_FLAGS_REQUEST,
            types.GET_FEATURE_FLAGS_SUCCESS,
            types.GET_FEATURE_FLAGS_FAILURE,
        ],
    },
});
// Used during onboarding
export const getCommonFeatureFlags = (deviceId, hasFb, platform, country) => ({
    [CALL_API]: {
        endpoint: `/common-flags?flags[]=savings_max_interest&flags[]=facebook_login&flags[]=apple_login&flags[]=google_login&flags[]=rent_reporting_two&flags[]=welcome_back_screen&&deviceId=${deviceId}&hasFb=${hasFb}&platform=${platform}&country=${country}`,
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        noAccessToken: true,
        types: [
            types.GET_FEATURE_FLAGS_REQUEST,
            types.GET_FEATURE_FLAGS_SUCCESS,
            types.GET_FEATURE_FLAGS_FAILURE,
        ],
    },
});
export const setScrambledData = (data) => ({
    type: types.SET_SCRAMBLED,
    payload: data,
});
export const sendAppsFlyerData = (body) => ({
    [CALL_API]: {
        endpoint: '/me/appsflyer',
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.SET_APPSFLYER_REQUEST,
            types.SET_APPSFLYER_SUCCESS,
            types.SET_APPSFLYER_FAILURE,
        ],
        muteAlert: true,
    },
});
export const setFCMToken = (fcmToken) => ({
    [CALL_API]: {
        endpoint: '/firebase-device-token/register',
        method: 'POST',
        body: JSON.stringify({
            token: fcmToken,
        }),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.SET_FCM_PUSH_REQUEST,
            types.SET_FCM_PUSH_SUCCESS,
            types.SET_FCM_PUSH_FAILURE,
        ],
        muteAlert: true,
    },
});
export const getServerHealth = () => ({
    [CALL_API]: {
        endpoint: '/',
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.GET_SERVER_HEALTH_REQUEST,
            types.GET_SERVER_HEALTH_SUCCESS,
            types.GET_SERVER_HEALTH_FAILURE,
        ],
        noAccessToken: true,
    },
});
export const getModalNotifications = () => ({
    [CALL_API]: {
        endpoint: '/in-app',
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.GET_MODAL_NOTIFICATIONS_REQUEST,
            types.GET_MODAL_NOTIFICATIONS_SUCCESS,
            types.GET_MODAL_NOTIFICATIONS_FAILURE,
        ],
    },
});
const patchModalNotification = (id, body, types, muteAlert) => ({
    [CALL_API]: {
        endpoint: `/in-app/${id}`,
        method: 'PATCH',
        body: JSON.stringify(body),
        extra: body,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types,
        muteAlert,
    },
});
export const seeModalNotification = (id) => patchModalNotification(id, { seenAt: moment().toISOString().replace('Z', '') }, [
    types.SEEN_MODAL_NOTIFICATIONS_REQUEST,
    types.SEEN_MODAL_NOTIFICATIONS_SUCCESS,
    types.SEEN_MODAL_NOTIFICATIONS_FAILURE,
], true);
export const dismissModalNotification = (id) => patchModalNotification(id, { dismissedAt: moment().toISOString().replace('Z', '') }, [
    types.DISMISS_MODAL_NOTIFICATIONS_REQUEST,
    types.DISMISS_MODAL_NOTIFICATIONS_SUCCESS,
    types.DISMISS_MODAL_NOTIFICATIONS_FAILURE,
]);
export const clickModalNotification = (id, setDimissed) => {
    const date = moment().toISOString().replace('Z', '');
    return patchModalNotification(id, { clickedAt: date, dismissedAt: setDimissed ? date : undefined }, [
        types.CLICKED_MODAL_NOTIFICATIONS_REQUEST,
        types.CLICKED_MODAL_NOTIFICATIONS_SUCCESS,
        types.CLICKED_MODAL_NOTIFICATIONS_FAILURE,
    ], true);
};
export const setUserColorScheme = (data) => ({
    type: types.SET_COLOR_SCHEME,
    payload: data,
});
export const setConnectionChangeHandled = (payload) => ({
    type: types.CONNECTION_CHANGE_HANDLED,
    payload,
});
