import React, { useEffect, useLayoutEffect, useMemo, useState, useCallback, } from 'react';
import { View } from 'react-native';
import Text from 'design-system/Text';
import { rem } from 'design-system/values';
import StatusBar from 'design-system/StatusBar';
import { AppView } from 'design-system/AppView';
import Button, { HEIGHT_PLUS_PADDING } from 'design-system/Button';
import { DaysOfTheWeek } from 'design-system/day-of-the-week-picker/values';
import withAnimatedNavigationHeader from 'design-system/hoc/withAnimatedNavigationHeader';
import WeeklyView from 'features/budgeting/components/WeeklyView';
import FixedDateView from 'features/budgeting/components/FixedDateView';
import EveryMonthView from 'features/budgeting/components/EveryMonthView';
import { TwiceAMonthView } from 'features/budgeting/components/TwiceAMonthView';
import { incomeToPeriod, toBudgetApiPayload, } from 'features/budgeting/utils';
import { muteAll, useFetchOld } from 'hooks';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import moment from 'moment';
import { Frequencies } from 'reducers/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import createStyleSheets from 'utils/createStyleSheets';
import { getOrdinalSuffix } from 'utils/formatting';
import { deleteAllIncomes, getIncomes, setIncome } from 'actions/payday';
import { budgetingPeriodChanged, fetchAnalyticsBudgetingData, } from 'actions/expenses';
import { useMarginBottom } from 'hooks/useMarginBottom';
import AccountHelpButton from 'features/invest/components/AccountHelpButton';
import EmptyComponent from 'design-system/EmptyComponent';
import { selectIncomes } from '../selectors';
import { isWeb } from '../../../constants';
export default withAnimatedNavigationHeader(({ navigation, route }) => {
    const { value, label, title, description2 } = route.params.option;
    const colors = useColors();
    const styles = useStyles(styleSet);
    const marginBottom = useMarginBottom();
    useLayoutEffect(() => {
        navigation.setOptions(isWeb
            ? { headerTitle: EmptyComponent }
            : {
                headerRight: () => <AccountHelpButton black/>,
            });
    }, [colors.background.light]);
    const { frequency: initFrequency, days: initDays, weekday: initWeekDay, weekNumber: initWeekNumber, ...restPeriod } = route.params.period || {};
    const [period, setPeriod] = useState({
        frequency: initFrequency || Frequencies.Monthly,
        weekday: initWeekDay || DaysOfTheWeek.Monday,
        weekNumber: initWeekNumber || 1,
        days: initDays || [],
        ...restPeriod,
    });
    const income = useAppSelector(selectIncomes);
    const dispatch = useAppDispatch();
    const [, , , fetch] = useFetchOld(muteAll);
    const { onSavePeriod, flow, monthlyBudget, budgetState } = route.params;
    const savePeriod = useCallback(async (period) => {
        if (onSavePeriod) {
            onSavePeriod(period);
        }
        else {
            if (value === Frequencies.Monthly) {
                await fetch(deleteAllIncomes());
            }
            else {
                await fetch(setIncome({ ...toBudgetApiPayload(period), isPrimary: true }));
            }
            dispatch(fetchAnalyticsBudgetingData());
            dispatch(budgetingPeriodChanged());
        }
        return false;
    }, [onSavePeriod, value]);
    const [bottomText, setBottomText] = useState();
    const [bottomTextVariableMonth, setBottomTextVariableMonth] = useState({
        on: period.weekNumber + getOrdinalSuffix(period.weekNumber || 1),
        daysOfTheWeek: DaysOfTheWeek[period.weekday || DaysOfTheWeek.Monday],
    });
    useEffect(() => {
        if (!onSavePeriod) {
            dispatch(getIncomes());
        }
    }, [onSavePeriod]);
    useEffect(() => {
        if (!onSavePeriod && income !== undefined) {
            setPeriod(incomeToPeriod(income));
        }
    }, [income, onSavePeriod]);
    const setDays = useCallback((days) => {
        if (value === Frequencies.FixedDate) {
            setBottomText(<Text TextThin-16 Secondary centered>
              Your period will reset on the{' '}
              <Text Text-16 Secondary>
                {days[0]}
                {getOrdinalSuffix(days[0])} day of each month
              </Text>
            </Text>);
        }
        else if (value === Frequencies.TwiceAMonth) {
            const sortedDays = days.slice().sort((a, b) => a - b);
            setBottomText(<Text TextThin-16 Secondary centered>
              Your period will reset on the{' '}
              <Text Text-16 Secondary>
                {sortedDays[0]}
                {getOrdinalSuffix(sortedDays[0])} and {sortedDays[1]}
                {getOrdinalSuffix(sortedDays[1])} day of each month
              </Text>
            </Text>);
        }
        setPeriod((prevPeriod) => ({ ...prevPeriod, days }));
    }, [value]);
    const setWeekDay = useCallback((weekday) => {
        if (value === Frequencies.EveryMonth ||
            value === Frequencies.EveryFourWeeks ||
            value === Frequencies.EveryTwoWeeks ||
            value === Frequencies.Weekly) {
            setBottomTextVariableMonth((prevState) => ({
                ...prevState,
                daysOfTheWeek: DaysOfTheWeek[weekday],
            }));
        }
        setPeriod((prevPeriod) => ({ ...prevPeriod, weekday }));
    }, [value]);
    const setWeekNumber = useCallback((weekNumber) => {
        if (value === Frequencies.EveryMonth) {
            setBottomTextVariableMonth((prevState) => ({
                ...prevState,
                on: `${weekNumber}${getOrdinalSuffix(weekNumber || 1)}`,
            }));
        }
        setPeriod((prevPeriod) => ({ ...prevPeriod, weekNumber }));
    }, [value]);
    const cycleContent = useMemo(() => {
        switch (value) {
            case Frequencies.FixedDate:
                return <FixedDateView days={period.days} onChange={setDays}/>;
            case Frequencies.EveryMonth:
                return (<EveryMonthView selectedDay={period.weekday} weekNumber={period.weekNumber} setWeekNumber={setWeekNumber} onSelectDay={setWeekDay}/>);
            case Frequencies.EveryFourWeeks:
                return (<WeeklyView selectedDay={period.weekday} onSelectDay={setWeekDay}/>);
            case Frequencies.EveryTwoWeeks:
                return (<WeeklyView selectedDay={period.weekday} onSelectDay={setWeekDay}/>);
            case Frequencies.Weekly:
                return (<WeeklyView selectedDay={period.weekday} onSelectDay={setWeekDay}/>);
            case Frequencies.TwiceAMonth:
                return <TwiceAMonthView days={period.days} onChange={setDays}/>;
            default:
                return null;
        }
    }, [
        period.days,
        period.weekNumber,
        period.weekday,
        setDays,
        setWeekDay,
        setWeekNumber,
        value,
    ]);
    const onSaveCycle = useCallback(() => {
        if (value === Frequencies.LastWorkingDay ||
            value === Frequencies.Monthly) {
            savePeriod({ ...period, frequency: value });
            navigation.goBack();
        }
        else if ([Frequencies.EveryTwoWeeks, Frequencies.EveryFourWeeks].includes(value)) {
            navigation.navigate('HolidaySelection', {
                flow: route.params.flow,
                keyBack: route.key,
                period,
                onSavePeriod: (period) => {
                    navigation.navigate('SelectDate', {
                        onSubmitDate: (startDate) => {
                            savePeriod({
                                ...period,
                                frequency: value,
                                startDate,
                            });
                            if (flow === 'SetAnalytics') {
                                return;
                            }
                            if (flow === 'Set') {
                                navigation.navigate('Budgets', {
                                    flow,
                                    monthlyBudget,
                                    budgetState,
                                    period: { ...period, frequency: value, startDate },
                                });
                            }
                            else {
                                navigation.navigate('EditBudget', {
                                    flow,
                                    budgetState,
                                    period: { ...period, frequency: value, startDate },
                                });
                            }
                        },
                        longTitle: 'When was your last\npayday?',
                        props: {
                            maxDate: moment().format(),
                        },
                    });
                },
            });
        }
        else {
            navigation.navigate('HolidaySelection', {
                flow: route.params.flow,
                keyBack: route.key,
                period,
                onSavePeriod: (period) => {
                    savePeriod({ ...period, frequency: value });
                    if (flow === 'SetAnalytics') {
                        return;
                    }
                    if (flow === 'Set') {
                        navigation.navigate('Budgets', {
                            flow,
                            monthlyBudget,
                            budgetState,
                            period: { ...period, frequency: value },
                        });
                    }
                    else {
                        navigation.navigate('EditBudget', {
                            flow,
                            budgetState,
                            period: { ...period, frequency: value },
                        });
                    }
                },
            });
        }
    }, [
        flow,
        value,
        period,
        route.key,
        savePeriod,
        budgetState,
        monthlyBudget,
        route.params.flow,
    ]);
    return (<AppView isInTab={isWeb}>
        <View style={styles.container}>
          <View>
            <StatusBar barStyle="dark-content"/>
            <View style={styles.headerContainer}>
              <Text Text-14 Secondary>
                {label}
              </Text>
              <Text Text-24 style={styles.title}>
                {title}
              </Text>
              <Text TextThin-14 Gray>
                {description2}
              </Text>
            </View>
            <View style={[
            styles.cycleContent,
            value !== Frequencies.FixedDate &&
                value !== Frequencies.TwiceAMonth && {
                backgroundColor: 'transparent',
                paddingHorizontal: 0,
            },
        ]}>
              {cycleContent}
            </View>
          </View>

          <View style={{
            marginBottom: Number(marginBottom.marginBottom || 0) + HEIGHT_PLUS_PADDING,
        }}>
            {value === Frequencies.EveryMonth && (<Text TextThin-16 Secondary centered>
                Your period will reset every
                <Text Text-16 Secondary>
                  {' '}
                  {bottomTextVariableMonth.on}{' '}
                  {bottomTextVariableMonth.daysOfTheWeek}{' '}
                </Text>
              </Text>)}

            {value === Frequencies.EveryFourWeeks && (<Text TextThin-16 Secondary centered>
                Your period will reset every
                <Text Text-16 Secondary>
                  {' '}
                  4 weeks{' '}
                </Text>
                on
                <Text Text-16 Secondary>
                  {' '}
                  {bottomTextVariableMonth.daysOfTheWeek}{' '}
                </Text>
              </Text>)}

            {value === Frequencies.EveryTwoWeeks && (<Text TextThin-16 Secondary centered>
                Your period will reset every
                <Text Text-16 Secondary>
                  {' '}
                  2 weeks{' '}
                </Text>
                on
                <Text Text-16 Secondary>
                  {' '}
                  {bottomTextVariableMonth.daysOfTheWeek}{' '}
                </Text>
              </Text>)}

            {value === Frequencies.Weekly && (<Text TextThin-16 Secondary centered>
                Your period will reset every
                <Text Text-16 Secondary>
                  {' '}
                  week{' '}
                </Text>
                on
                <Text Text-16 Secondary>
                  {' '}
                  {bottomTextVariableMonth.daysOfTheWeek}{' '}
                </Text>
              </Text>)}

            {(value === Frequencies.FixedDate ||
            value === Frequencies.TwiceAMonth) &&
            bottomText}
          </View>
        </View>
        <Button title="Continue" brand floating onPress={onSaveCycle}/>
      </AppView>);
}, ({ colors, route }) => ({
    headerColorOutputRange: [
        colors.background.dark,
        colors.cards.onDark,
    ],
    hasCustomTitle: true,
    title: route.params.option?.label,
}));
const styleSet = createStyleSheets((colors) => ({
    container: {
        marginHorizontal: rem(16),
        flex: 1,
        justifyContent: 'space-between',
    },
    headerContainer: {
        paddingVertical: rem(16),
    },
    cycleContent: {
        padding: rem(16),
        backgroundColor: colors.cards.onDark,
        borderRadius: rem(16),
    },
    title: {
        marginBottom: rem(9),
    },
}));
