import React, { useMemo } from 'react';
import { View } from 'react-native';
import moment from 'moment';
import Text from 'design-system/Text';
import Amount from 'design-system/Amount';
import Spacer from 'design-system/Spacer';
import { rem } from 'design-system/values';
import BlurAmountView from 'design-system/BlurAmountView';
import useStyles from 'hooks/useStyles';
import useColors from 'hooks/useColors';
import createStyleSheets from 'utils/createStyleSheets';
import TouchableHighlight from 'utils/packages/TouchableHighlight';
import IconExcluded from 'features/subscriptions/icons/IconExcluded';
import PriceChange from './PriceChange';
import { isWeb } from '../../../../constants';
import SubscriptionLogo from '../SubscriptionLogo';
const RowSubscription = ({ data, isCommitted, isExcluded, extraText, switchingButton, onPress, blurValues, btnStyles, }) => {
    const colors = useColors();
    const { priceChange, merchantInfo, customName, currency, predictions, prediction, } = data;
    const price = Math.abs(data.nativePrice.amount);
    const nextPrediction = predictions && predictions[0] ? predictions[0] : null;
    const rowPressed = () => {
        onPress(data);
    };
    const date = useMemo(() => {
        if (prediction?.date) {
            return moment(prediction?.date).format('D MMM');
        }
        if (nextPrediction) {
            return moment(nextPrediction.date).format('D MMM');
        }
        return undefined;
    }, [nextPrediction, prediction]);
    const name = customName || (merchantInfo && merchantInfo.displayName);
    const styles = useStyles(styleSet);
    return (<TouchableHighlight onCard hoverOnLight style={btnStyles} activeOpacity={1} onPress={rowPressed} underlayColor={colors.background.underlay}>
      <View style={styles.rowContainer}>
        <View>
          <SubscriptionLogo R44 url={data.merchantInfo?.iconUrl}/>
        </View>
        <View style={styles.textContainer}>
          <View style={styles.leftContainer}>
            <BlurAmountView enabled={blurValues}>
              <Text Text-16 {...(isExcluded ? { Gray: true } : { OffBlack: true })} numberOfLines={1} ellipsizeMode="tail">
                {name}
              </Text>
            </BlurAmountView>
            <BlurAmountView enabled={blurValues}>
              {priceChange && priceChange !== 0 && !isCommitted ? (<PriceChange priceChange={priceChange} currency={currency}/>) : null}
            </BlurAmountView>
            {extraText ? (<BlurAmountView enabled={blurValues}>
                <Text TextThin-14 Gray>
                  {extraText}
                </Text>
              </BlurAmountView>) : null}
            {isExcluded ? (<View style={styles.excluded}>
                <IconExcluded style={styles.excludedIcon}/>
                <Text TextThin-14 Gray>
                  Excluded
                </Text>
              </View>) : (switchingButton)}
          </View>
          <Spacer w={8}/>
          <BlurAmountView enabled={blurValues}>
            <View style={styles.rightContainer}>
              <Amount Numbers-16 {...(isExcluded ? { Gray: true } : { OffBlack: true })} value={{ value: Math.abs(price), currency }}/>
              <Text TextThin-14 Gray>
                {date}
              </Text>
            </View>
          </BlurAmountView>
        </View>
      </View>
    </TouchableHighlight>);
};
const styleSet = createStyleSheets(() => ({
    rowContainer: {
        flexDirection: 'row',
        padding: rem(16),
        ...(isWeb && {
            paddingVertical: rem(16),
            paddingHorizontal: rem(8),
        }),
    },
    textContainer: {
        flexDirection: 'row',
        flex: 1,
        marginLeft: rem(16),
        justifyContent: 'space-between',
    },
    leftContainer: {
        ...(!isWeb && {
            alignItems: 'flex-start',
        }),
        justifyContent: 'center',
        flex: 1,
    },
    rightContainer: {
        justifyContent: 'center',
        alignItems: 'flex-end',
    },
    excluded: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    excludedIcon: {
        marginEnd: rem(8),
    },
}));
export default RowSubscription;
