import _ from 'lodash';
import { LOGGED_OUT } from 'actions/types';
import { sortAccordingToTimestamp } from 'utils/sort';
import { filterActivities } from '../utils';
import { getAnalystDetails, getParsedAnalystData } from '../utils/format';
export const initialState = {
    filledDayTrades: undefined,
    pendingDayTrades: undefined,
    recurringBuys: [],
    recurringBuysForAssetId: {},
    historyForRecurringBuyId: {},
    isErrorInFetchingInvestAcc: undefined,
};
const unpersistedInvestReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'INVEST_GET_ANALYST_INFO_SUCCESS_ACTION': {
            const analysisInfo = getParsedAnalystData(action.payload.analystInfo);
            if (analysisInfo) {
                return {
                    ...state,
                    analystInfoMap: {
                        ...state.analystInfoMap,
                        [action.extra.symbol]: analysisInfo,
                    },
                };
            }
            return state;
        }
        case 'GET_ANALYST_RATINGS_DETAILS_SUCCESS_ACTION': {
            const analystDetails = getAnalystDetails(action.payload.analystInfo);
            return {
                ...state,
                analystInfoDetailsMap: {
                    ...state.analystInfoDetailsMap,
                    [action.payload.symbol]: analystDetails,
                },
            };
        }
        case 'CACHE_ACCOUNTS':
        case 'CACHE_TRADING_ACCOUNT':
        case 'GET_TRADING_ACCOUNT_SUCCESS': {
            return {
                ...state,
                isErrorInFetchingInvestAcc: false,
            };
        }
        case 'GET_PATTERN_DAY_TRADING_INFO_SUCCESS': {
            return {
                ...state,
                filledDayTrades: action.payload.filledDayTrades,
                pendingDayTrades: action.payload.pendingDayTrades,
            };
        }
        case 'GET_PATTERN_DAY_TRADING_INFO_FAILURE': {
            return {
                ...state,
                filledDayTrades: undefined,
                pendingDayTrades: undefined,
            };
        }
        case 'GET_TRADING_ACCOUNT_FAILURE': {
            return {
                ...state,
                isErrorInFetchingInvestAcc: true,
            };
        }
        case 'GET_RECURRING_BUYS_SUCCESS': {
            if (action.extra) {
                return {
                    ...state,
                    recurringBuysForAssetId: {
                        ...state.recurringBuysForAssetId,
                        [action.extra]: action.payload.recurringBuys,
                    },
                };
            }
            return {
                ...state,
                recurringBuys: action.payload.recurringBuys,
            };
        }
        case 'DELETE_RECURRING_BUY_SUCCESS': {
            const { id, assetId } = action.extra;
            return {
                ...state,
                recurringBuys: state.recurringBuys?.filter((recurringBuy) => recurringBuy.id !== id),
                recurringBuysForAssetId: {
                    ...state.recurringBuysForAssetId,
                    [assetId]: state.recurringBuysForAssetId?.[assetId]?.filter((recurringBuy) => recurringBuy.id !== id) || [],
                },
            };
        }
        case 'UPDATE_RECURRING_BUY_SUCCESS': {
            const { recurringBuy } = action.payload;
            return {
                ...state,
                recurringBuys: state.recurringBuys?.map((oldRecurringBuy) => {
                    if (oldRecurringBuy.id === recurringBuy.id) {
                        return recurringBuy;
                    }
                    return oldRecurringBuy;
                }),
                recurringBuysForAssetId: {
                    ...state.recurringBuysForAssetId,
                    [recurringBuy.assetId]: state.recurringBuysForAssetId?.[recurringBuy.assetId]?.map((oldRecurringBuy) => {
                        if (oldRecurringBuy.id === recurringBuy.id) {
                            return recurringBuy;
                        }
                        return oldRecurringBuy;
                    }) || [],
                },
            };
        }
        case 'GET_RECURRING_BUY_ACTIVITIES_SUCCESS': {
            const { id } = action.extra;
            const { activities, paging } = action.payload;
            const filteredActivities = activities.filter(filterActivities);
            if (paging.page === 1) {
                return {
                    ...state,
                    historyForRecurringBuyId: {
                        ...state.historyForRecurringBuyId,
                        [id]: filteredActivities,
                    },
                };
            }
            return {
                ...state,
                historyForRecurringBuyId: {
                    ...state.historyForRecurringBuyId,
                    [id]: _.uniqBy(state.historyForRecurringBuyId?.[id]?.concat(filteredActivities) ?? filteredActivities, (i) => i.id).sort(sortAccordingToTimestamp),
                },
            };
        }
        case LOGGED_OUT: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};
export default unpersistedInvestReducer;
