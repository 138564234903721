import { REHYDRATE } from 'redux-persist/lib/constants';
import equal from 'fast-deep-equal/es6';
import { splitArrayToNParts } from 'utils/arrayHelpers';
import { LOGGED_OUT } from 'actions/types';
import { RECENTLY_VIEWED_MAX_COUNT } from '../constants';
import * as localTypes from '../actions/types';
import { StaticCollectionIds } from '../types';
function getMTimestamp() {
    return new Date().getTime() / 60000;
}
function updateStockMetaCache(cache, assets) {
    if (assets.length) {
        const newCache = { ...cache };
        assets.forEach((asset) => {
            const { symbol, icon, name } = asset;
            // Adding extra check
            const idObj = 'id' in asset && asset.id ? { id: asset.id } : {};
            const description = 'description' in asset ? asset.description : undefined;
            newCache[symbol] = {
                ...cache[symbol],
                name,
                iconUrl: icon,
                ...idObj,
                ...(description
                    ? {
                        description,
                    }
                    : {}),
            };
        });
        return newCache;
    }
    return cache;
}
function updateCurrentPriceCache(cache, assets) {
    if (assets.length) {
        const newCache = { ...cache };
        const mTimestamp = getMTimestamp();
        assets.forEach((asset) => {
            const { symbol, sharePrice } = asset;
            if (sharePrice !== undefined) {
                newCache[symbol] = {
                    value: sharePrice,
                    currency: 'USD',
                    mTimestamp,
                };
            }
        });
        return newCache;
    }
    return cache;
}
function updatePriceChangeCache(cache, assets) {
    if (assets.length) {
        const newCache = { ...cache };
        assets.forEach((asset) => {
            const { symbol, sharePriceChange, sharePriceChangePct } = asset;
            newCache[symbol] = {
                value: sharePriceChange,
                percent: sharePriceChangePct,
            };
        });
        return newCache;
    }
    return cache;
}
/**
 * It won't insert it if it's already there.
 */
function insertOrderId(id, idArray, orderCache) {
    if (idArray.findIndex((orderId) => orderId === id) !== -1) {
        return idArray;
    }
    const result = [id, ...idArray];
    const timestamps = {};
    for (let index = 0; index < result.length; index += 1) {
        const order = orderCache[result[index]];
        if (order) {
            timestamps[order.id] = new Date(order.timestamp).getTime();
        }
        else {
            return [id, ...idArray]; // some orders were not cached, we cannot perform sorting. Let's put this order in front
        }
    }
    result.sort((a, b) => timestamps[b] - timestamps[a]);
    return result;
}
export const initialState = {
    status: undefined,
    account: undefined,
    hasFunded: undefined,
    hasTraded: undefined,
    connectedAccount: null,
    cache: {},
    priceChangeCache: {},
    recentlyViewedCache: [],
    currentPriceCache: {},
    ordersCache: {},
    sectorsCache: [],
    collectionsCache: {
        collection1: [],
        collection2: [],
        collection3: [],
    },
    orderIdForSymbolCache: {},
    orderIdCache: [],
    pendingOrderIdForSymbolCache: {},
    pendingOrderIdCache: [],
    activityCache: {},
    activityIdForSymbolCache: {},
    positionsCache: {},
    watchlists: [],
    topTrending: [],
    newlyAdded: [],
    mostWatchedStocks: [],
    mostHeldStocks: [],
    losers: [],
    gainers: [],
    kycDocumentType: undefined,
    tierFxRateMap: undefined,
    categoryIdSectorMap: undefined,
    hasCompletedOnboardingFunnel: undefined,
    requiredNationalityIdentifiers: undefined,
};
const zero = { currency: 'GBP', value: 0 };
const handledSectorNames = [
    'Finance',
    'Health',
    'Tech',
    'Industrials',
    'Energy',
    'Consumer Goods',
    'Property',
    'Services',
    'Mining',
    'Entertainment',
    'Food & Drink',
    'Cars',
    'Logistics',
    'Construction',
    'Telco',
    'Utilities',
    'Travel',
    'Fashion',
];
const reducer = (state = initialState, action) => {
    switch (action?.type) {
        case REHYDRATE: {
            if (action.payload && action.payload.invest) {
                return {
                    ...state,
                    ...action.payload.invest,
                };
            }
            return state;
        }
        case LOGGED_OUT: {
            return initialState;
        }
        case 'CLOSE_USER_TRADING_ACCOUNT_SUCCESS_ACTION': {
            return {
                ...state,
                onboarding: undefined,
                account: {
                    ...state.account,
                    status: action.payload.status,
                    reopenBlockedUntil: action.payload.reopenBlockedUntil,
                },
            };
        }
        case 'CLEAR_TRADING_BLOCK_EXPIRY_DATE_ACTION': {
            if (state.account) {
                return {
                    ...state,
                    account: {
                        ...state.account,
                        tradingBlockExpiryDate: undefined,
                    },
                };
            }
            return state;
        }
        case 'INVEST_ADD_BUY_BLOCK_SUCCESS_ACTION': {
            if (state.account) {
                return {
                    ...state,
                    account: {
                        ...state.account,
                        tradingBlockExpiryDate: action.extra.blockUntilDate,
                    },
                };
            }
            return state;
        }
        case 'ONBOARDING_FUNNEL_COMPLETED': {
            return {
                ...state,
                hasCompletedOnboardingFunnel: true,
            };
        }
        case 'GET_POTS_ACCOUNT_SUCCESS': {
            return {
                ...state,
                hasFunded: action.payload.hasFunded,
            };
        }
        case 'GET_SECTORS_SUCCESS': {
            const { payload } = action;
            if (payload?.categories?.length) {
                const categoryIdSectorMap = {};
                payload.categories.forEach((category) => {
                    if (handledSectorNames.includes(category.name)) {
                        categoryIdSectorMap[category.id] = category;
                    }
                });
                return {
                    ...state,
                    categoryIdSectorMap,
                };
            }
            return initialState;
        }
        case 'GET_FX_FEES_SUCCESS': {
            const tierFxRateMap = {};
            action.payload.feeTiers.forEach((rateObj) => {
                tierFxRateMap[rateObj.tier] = rateObj.percentageFee.toFixed(2);
            });
            return {
                ...state,
                tierFxRateMap,
            };
        }
        case 'DEPOSIT_POT_SUCCESS':
        case 'WITHDRAW_POT_SUCCESS': {
            if (state.account) {
                return {
                    ...state,
                    account: {
                        ...state.account,
                        walletBalance: action.payload.walletBalance,
                        cashUnsettled: action.payload.cashUnsettled,
                        cashWithdrawable: action.payload.cashWithdrawable,
                        availableToInvest: action.payload.availableToInvest,
                    },
                };
            }
            return state;
        }
        case 'GET_BIGGEST_DAILY_MOVERS_SUCCESS': {
            const { gainers, losers } = action.payload;
            const combinedList = [...gainers, ...losers];
            const newCache = { ...state.cache };
            const newCurrentPriceCache = { ...state.currentPriceCache };
            const mTimestamp = getMTimestamp();
            combinedList.forEach((asset) => {
                newCache[asset.symbol] = {
                    ...state.cache[asset.symbol],
                    iconUrl: asset.icon,
                    name: asset.name,
                };
                newCurrentPriceCache[asset.symbol] = {
                    mTimestamp,
                    value: asset.sharePrice,
                    currency: asset.sharePriceCurrency,
                };
            });
            return {
                ...state,
                cache: newCache,
                currentPriceCache: newCurrentPriceCache,
                losers: losers.map((asset) => asset.symbol),
                gainers: gainers.map((asset) => asset.symbol),
                priceChangeCache: updatePriceChangeCache(state.priceChangeCache, combinedList),
            };
        }
        case 'GET_STATIC_COLLECTION_STOCKS_SUCCESS': {
            const { type } = action.extra;
            const { assets } = action.payload;
            let key = '';
            switch (type) {
                case 'BIGGEST_GAINERS':
                    key = 'gainers';
                    break;
                case 'BIGGEST_LOSERS':
                    key = 'losers';
                    break;
                case 'MOST_POPULAR':
                    key = 'mostHeldStocks';
                    break;
                default:
                    break;
            }
            if (key) {
                return {
                    ...state,
                    [key]: assets.map((asset) => asset.symbol),
                    cache: updateStockMetaCache(state.cache, assets),
                    currentPriceCache: updateCurrentPriceCache(state.currentPriceCache, assets),
                    priceChangeCache: updatePriceChangeCache(state.priceChangeCache, assets),
                };
            }
            return {
                ...state,
                cache: updateStockMetaCache(state.cache, assets),
                currentPriceCache: updateCurrentPriceCache(state.currentPriceCache, assets),
                priceChangeCache: updatePriceChangeCache(state.priceChangeCache, assets),
            };
        }
        case 'CACHE_ACCOUNTS': {
            const { tradingAccount, connectedAccount } = action;
            /**
             * since the creation of invest account is async, the user might already have status 'IDENTITY_CHECKS_IN_PROGRESS'
             * but the server might return 'NOT_STARTED', so we need this check
             */
            const newStatus = tradingAccount.status === 'NOT_STARTED' && state.status === 'IDENTITY_CHECKS_IN_PROGRESS'
                ? state.status
                : tradingAccount.status;
            return {
                ...state,
                status: newStatus,
                account: { ...tradingAccount, status: newStatus },
                positionsCache: newStatus !== 'ACTIVE' ? {} : state.positionsCache,
                connectedAccount,
            };
        }
        case 'SET_KYC_DOCUMENT_TYPE': {
            return {
                ...state,
                kycDocumentType: action.documentType,
            };
        }
        case 'SET_COLLECTIONS': {
            let collection1 = [];
            let collection2 = [];
            let collection3 = [];
            const { collections: dynamicCollections } = action;
            const collections = [
                // Commenting for now because we need to add it later
                // {
                //   emoji: '💹',
                //   name: 'Gainers',
                //   id: StaticCollectionIds.BIGGEST_GAINERS,
                // },
                // {
                //   emoji: '📉',
                //   name: 'Losers',
                //   id: StaticCollectionIds.BIGGEST_LOSERS,
                // },
                {
                    emoji: '🤑',
                    name: 'Low P/E',
                    id: StaticCollectionIds.HIGHEST_EARNERS,
                    description: "These companies have the highest earnings relative to their stock price. This is called P/E ratio and measures the relationship between a company's stock price and its earnings per share. A low but positive P/E ratio stands for a company that is generating high earnings compared to its current valuation. Low P/E ratios also occur when the market expects the stocks earnings to fall in the future.",
                },
                {
                    emoji: '💸',
                    name: 'Dividend yield',
                    id: StaticCollectionIds.HIGHEST_DIVIDEND_YIELD,
                    description: 'These companies pay the highest dividends relative to their stock price.',
                },
                {
                    emoji: '🥇',
                    name: 'Top 100',
                    id: StaticCollectionIds.HIGHEST_MARKET_CAP,
                    description: 'These are the top 100 companies in Emma by market capitalization.',
                },
                {
                    emoji: '👀',
                    name: 'Most watched',
                    id: StaticCollectionIds.MOST_WATCHED,
                    description: 'These are the most watched stocks in Emma for the past 30 days.',
                },
                ...dynamicCollections,
            ];
            const collectionsLength = collections.length;
            if (collectionsLength) {
                if (collectionsLength > 20) {
                    [collection1, collection2, collection3] = splitArrayToNParts(collections, 3);
                }
                else if (collectionsLength > 10) {
                    [collection1, collection2] = splitArrayToNParts(collections, 2);
                }
                else {
                    collection1 = collections;
                }
            }
            return {
                ...state,
                collectionsCache: {
                    collection1,
                    collection2,
                    collection3,
                },
            };
        }
        case localTypes.SET_ACCOUNT_STATUS: {
            const { status } = action;
            /**
             * since the creation of invest account is async, the user might already have status 'IDENTITY_CHECKS_IN_PROGRESS'
             * but the server might return 'NOT_STARTED', so we need this check
             */
            const newStatus = status === 'NOT_STARTED' && state.status === 'IDENTITY_CHECKS_IN_PROGRESS' ? state.status : status;
            return {
                ...state,
                status: newStatus,
                account: state.account
                    ? { ...state.account, status: newStatus }
                    : {
                        status: newStatus,
                        currency: 'GBP',
                        walletBalance: zero,
                        availableToInvest: zero,
                        cashWithdrawable: zero,
                        cashUnsettled: zero,
                        cashTotal: zero,
                        cashReserved: zero,
                        longPositionsMarketValue: zero,
                        timestamp: new Date().toISOString(),
                    },
            };
        }
        case localTypes.CREATE_TRADING_ACCOUNT_SUCCESS: {
            const { payload } = action;
            if (payload.success) {
                return { ...state, status: 'IDENTITY_CHECKS_IN_PROGRESS', onboarding: undefined };
            }
            return state;
        }
        case 'CLEAR_ONBOARDING_STATE': {
            return { ...state, onboarding: undefined };
        }
        case localTypes.GET_TRADING_ACCOUNT_SUCCESS: {
            const { payload } = action;
            /**
             * since the creation of invest account is async, the user might already have status 'IDENTITY_CHECKS_IN_PROGRESS'
             * but the server might return 'NOT_STARTED', so we need this check
             */
            const newStatus = payload.account.status === 'NOT_STARTED' && state.status === 'IDENTITY_CHECKS_IN_PROGRESS'
                ? state.status
                : payload.account.status;
            return {
                ...state,
                status: newStatus,
                account: { ...payload.account, status: newStatus },
                hasFunded: payload.hasFunded,
                hasTraded: payload.hasTraded,
                positionsCache: newStatus !== 'ACTIVE' ? {} : state.positionsCache,
            };
        }
        case localTypes.CACHE_TRADING_ACCOUNT: {
            const { payload } = action;
            /**
             * since the creation of invest account is async, the user might already have status 'IDENTITY_CHECKS_IN_PROGRESS'
             * but the server might return 'NOT_STARTED', so we need this check
             */
            const newStatus = payload.account.status === 'NOT_STARTED' && state.status === 'IDENTITY_CHECKS_IN_PROGRESS'
                ? state.status
                : payload.account.status;
            return {
                ...state,
                status: newStatus,
                account: { ...payload.account, status: newStatus },
                positionsCache: newStatus !== 'ACTIVE' ? {} : state.positionsCache,
            };
        }
        case localTypes.GET_CONNECTED_ACCOUNT_SUCCESS: {
            const { payload } = action;
            return {
                ...state,
                connectedAccount: payload.account,
            };
        }
        case localTypes.GET_STOCK_META_SUCCESS: {
            const { payload } = action;
            const newCache = { ...state.cache };
            payload.companies.forEach((stockMeta) => {
                newCache[stockMeta.symbol] = {
                    ...state.cache[stockMeta.symbol],
                    iconUrl: stockMeta.iconUrl,
                    name: stockMeta.companyName,
                    ...(stockMeta.description
                        ? {
                            description: stockMeta.description,
                        }
                        : {}),
                };
            });
            if (payload.companies.length > 0) {
                return { ...state, cache: newCache };
            }
            return state;
        }
        case localTypes.GET_POSITIONS_SUCCESS: {
            const { extra, payload } = action;
            const requestedSymbols = extra.symbols;
            const newCache = requestedSymbols === undefined ? {} : { ...state.positionsCache };
            const newCurrentPriceCache = { ...state.currentPriceCache };
            if (payload.positions.length > 0) {
                const mTimestamp = getMTimestamp();
                payload.positions.forEach((position) => {
                    newCache[position.symbol] = position; // there can only be one single position per symbol
                    // We update the current price of this symbol because we can - position contains the data about it
                    // TODO: we need to also update the price change, but it doesn't come in this object. Maybe fire another request to API to update it, and remove 4 lines below
                    newCurrentPriceCache[position.symbol] = {
                        ...position.price,
                        mTimestamp, // TODO: use price's timestamp from position when it will be available
                    };
                });
                return {
                    ...state,
                    positionsCache: newCache,
                    currentPriceCache: newCurrentPriceCache,
                };
            }
            if (requestedSymbols !== undefined) {
                // We were fetching a list of positions for specific symbols and zero positions were returned.
                // We need to clear positions for those symbols.
                requestedSymbols.forEach((symbol) => {
                    newCache[symbol] = undefined; // we don't use delete since it slows down the performance of object
                });
                return {
                    ...state,
                    positionsCache: newCache,
                };
            }
            return { ...state, positionsCache: newCache };
        }
        case localTypes.GET_ORDERS_SUCCESS: {
            const { extra, payload } = action;
            if (extra.symbols && extra.symbols.length > 1) {
                // Logic below doesn't work when we were fetching for more than one symbol.
                // It only works if we were fetching one or all symbols.
                log('[invest reducer] Plese update GET_ORDERS_SUCCESS handling', false, 'yellow');
            }
            const responseIncludesPendingOrders = !extra.statuses || extra.statuses.findIndex((status) => status === 'pending') !== -1;
            const responseIncludesQueuedOrders = !extra.statuses || extra.statuses.findIndex((status) => status === 'queued') !== -1;
            const page1Match = 'page' in extra.paging ? extra.paging.page === 1 : false;
            const requestedSymbols = extra.symbols;
            const newCache = {};
            const newPendingCache = {};
            const ordersCache = { ...state.ordersCache };
            const newOrderIdCache = [];
            const newPendingOrderIdCache = [];
            if (payload.orders.length > 0) {
                payload.orders.forEach((order) => {
                    const { id, symbol, status } = order;
                    if (status === 'queued') {
                        const cacheForSymbol = newCache[symbol];
                        if (cacheForSymbol) {
                            cacheForSymbol.push(id);
                        }
                        else {
                            newCache[symbol] = [id];
                        }
                    }
                    else if (status === 'pending') {
                        const cacheForSymbol = newPendingCache[symbol];
                        if (cacheForSymbol) {
                            cacheForSymbol.push(id);
                        }
                        else {
                            newPendingCache[symbol] = [id];
                        }
                    }
                    ordersCache[id] = order;
                    if (page1Match && !requestedSymbols) {
                        if (status === 'queued') {
                            newOrderIdCache.push(id);
                        }
                        else if (status === 'pending') {
                            newPendingOrderIdCache.push(id);
                        }
                    }
                });
                // TODO: handle case when extra.symbols contains two or more symbols
                // Right now, the app only fetches orders either for all symbols or one symbol
                const newState = {
                    ...state,
                    ordersCache,
                    orderIdForSymbolCache: {
                        ...state.orderIdForSymbolCache,
                        ...newCache,
                    },
                    pendingOrderIdForSymbolCache: {
                        ...state.pendingOrderIdForSymbolCache,
                        ...newPendingCache,
                    },
                };
                if (page1Match && !requestedSymbols) {
                    if (responseIncludesQueuedOrders) {
                        // the condition above fixes potential issue of disappearing queued orders in Portfolio, after user visits pending orders screen
                        newState.orderIdCache = newOrderIdCache;
                    }
                    if (responseIncludesPendingOrders) {
                        // the condition above fixes potential issue of disappearing pending orders in Portfolio, in case we decide to show them there
                        newState.pendingOrderIdCache = newPendingOrderIdCache;
                    }
                }
                return newState;
            }
            // payload did not contain any orders - code below checks if we need to remove some orders from cache
            if (requestedSymbols) {
                // the request was targeting specific symbols, so only touch those symbols
                const didUpdateOrdersCache = false;
                // Commenting out lines below, since cancelled orders are still needed for cancelled activity
                // const prevOrderIds = state.orderIdForSymbolCache[requestedSymbol];
                // if (prevOrderIds?.length) {
                //   // clean up all orders in ordersCache for previous orders of requestedSymbol
                //   prevOrderIds.forEach((orderId) => {
                //     if (ordersCache[orderId]) {
                //       delete ordersCache[orderId];
                //       didUpdateOrdersCache = true;
                //     }
                //   });
                // }
                let shouldUpdateQueuedOrderIdForSymbolCache = false;
                let shouldUpdatePendingOrderIdForSymbolCache = false;
                requestedSymbols.forEach((symbol) => {
                    if (responseIncludesQueuedOrders && state.orderIdForSymbolCache[symbol]?.length) {
                        // there were some queued orders for that symbol in the cache
                        shouldUpdateQueuedOrderIdForSymbolCache = true;
                    }
                    if (responseIncludesPendingOrders && state.pendingOrderIdForSymbolCache[symbol]?.length) {
                        // there were some pending orders for that symbol in the cache
                        shouldUpdatePendingOrderIdForSymbolCache = true;
                    }
                });
                if (didUpdateOrdersCache ||
                    shouldUpdateQueuedOrderIdForSymbolCache ||
                    shouldUpdatePendingOrderIdForSymbolCache) {
                    const newState = { ...state };
                    if (didUpdateOrdersCache) {
                        newState.ordersCache = ordersCache;
                    }
                    if (shouldUpdateQueuedOrderIdForSymbolCache) {
                        newState.orderIdForSymbolCache = { ...state.orderIdForSymbolCache };
                        requestedSymbols.forEach((symbol) => {
                            newState.orderIdForSymbolCache[symbol] = []; // not using delete since it slows down the performance
                        });
                    }
                    if (shouldUpdatePendingOrderIdForSymbolCache) {
                        newState.pendingOrderIdForSymbolCache = { ...state.pendingOrderIdForSymbolCache };
                        requestedSymbols.forEach((symbol) => {
                            newState.pendingOrderIdForSymbolCache[symbol] = []; // not using delete since it slows down the performance
                        });
                    }
                    return newState;
                }
            }
            else if (page1Match) {
                // portfolio fetched for orders, but there are no queued or pending orders currently - so reset those arrays
                return {
                    ...state,
                    orderIdForSymbolCache: {},
                    pendingOrderIdForSymbolCache: responseIncludesPendingOrders ? {} : state.pendingOrderIdForSymbolCache,
                    orderIdCache: [],
                    pendingOrderIdCache: responseIncludesPendingOrders ? [] : state.pendingOrderIdCache,
                };
            }
            return state;
        }
        case localTypes.GET_ACTIVITIES_SUCCESS: {
            const { payload } = action;
            const newCache = {};
            const activityCache = {
                ...state.activityCache,
            };
            let i = 0;
            payload.activities.forEach((activity) => {
                const { id } = activity;
                if ('symbol' in activity) {
                    const { status } = activity;
                    if (status !== 'failed' && status !== 'pending') {
                        i += 1;
                        const { symbol } = activity;
                        const cacheForSymbol = newCache[symbol];
                        if (cacheForSymbol) {
                            // prevent duplicates
                            if (cacheForSymbol.findIndex((x) => x === id) === -1) {
                                cacheForSymbol.push(id);
                            }
                        }
                        else {
                            newCache[symbol] = [id];
                        }
                        activityCache[id] = activity;
                    }
                }
            });
            if (i > 0) {
                const newActivityIdForSymbolCache = {
                    ...state.activityIdForSymbolCache,
                    ...newCache,
                };
                if (!equal(activityCache, state.activityCache) ||
                    !equal(newActivityIdForSymbolCache, state.activityIdForSymbolCache)) {
                    return {
                        ...state,
                        activityCache,
                        activityIdForSymbolCache: newActivityIdForSymbolCache,
                    };
                }
            }
            return state;
        }
        case localTypes.CREATE_ORDER_SUCCESS: {
            const { payload } = action;
            const { id, symbol, status } = payload.order;
            if (status === 'queued') {
                const newOrdersCache = {
                    ...state.ordersCache,
                };
                const oldCacheForSymbol = state.orderIdForSymbolCache[symbol] ?? [];
                const newCacheForSymbol = [id, ...oldCacheForSymbol];
                newOrdersCache[id] = payload.order;
                const newOrderIdForSymbolCache = {
                    ...state.orderIdForSymbolCache,
                    [symbol]: newCacheForSymbol,
                };
                const newOrderIdCache = [id, ...state.orderIdCache];
                return {
                    ...state,
                    ordersCache: newOrdersCache,
                    orderIdForSymbolCache: newOrderIdForSymbolCache,
                    orderIdCache: newOrderIdCache,
                };
            }
            if (status === 'pending') {
                const newOrdersCache = {
                    ...state.ordersCache,
                };
                const oldCacheForSymbol = state.pendingOrderIdForSymbolCache[symbol] ?? [];
                const newCacheForSymbol = [id, ...oldCacheForSymbol];
                newOrdersCache[id] = payload.order;
                const newOrderIdForSymbolCache = {
                    ...state.pendingOrderIdForSymbolCache,
                    [symbol]: newCacheForSymbol,
                };
                const newOrderIdCache = [id, ...state.pendingOrderIdCache];
                return {
                    ...state,
                    ordersCache: newOrdersCache,
                    pendingOrderIdForSymbolCache: newOrderIdForSymbolCache,
                    pendingOrderIdCache: newOrderIdCache,
                };
            }
            return state;
        }
        case localTypes.CACHE_CURRENT_STOCK_PRICE: {
            const { symbol, value, currency, mTimestamp } = action;
            return {
                ...state,
                currentPriceCache: {
                    ...state.currentPriceCache,
                    [symbol]: { value, currency, mTimestamp },
                },
            };
        }
        case localTypes.GET_ORDER_SUCCESS: {
            const { payload: { order }, } = action;
            const { id, status, symbol } = order;
            const cachedOrder = state.ordersCache[id];
            const newState = {
                ...state,
                ordersCache: { ...state.ordersCache, [order.id]: order },
            };
            if (cachedOrder) {
                // we had this order in cache, check its status and if status changed then update our queued/pending id arrays
                const { status: cachedStatus } = cachedOrder;
                if (status !== cachedStatus) {
                    // status changed, update id lists according to that
                    if (cachedStatus === 'pending') {
                        // drop this order from pending list
                        newState.pendingOrderIdCache = state.pendingOrderIdCache.filter((pendingOrderId) => pendingOrderId !== id);
                    }
                    else if (cachedStatus === 'queued') {
                        // drop this order from queued list
                        newState.orderIdCache = state.orderIdCache.filter((queuedOrderId) => queuedOrderId !== id);
                    }
                }
            }
            // make sure this order exists in order id lists, if it should
            if (status === 'queued') {
                newState.orderIdCache = insertOrderId(id, state.orderIdCache, newState.ordersCache);
                const forSymbol = state.orderIdForSymbolCache[symbol];
                if (forSymbol) {
                    newState.orderIdForSymbolCache[symbol] = insertOrderId(id, forSymbol, newState.ordersCache);
                }
                else {
                    newState.orderIdForSymbolCache[symbol] = [id];
                }
            }
            else if (status === 'pending') {
                newState.pendingOrderIdCache = insertOrderId(id, state.pendingOrderIdCache, newState.ordersCache);
                const forSymbol = state.pendingOrderIdForSymbolCache[symbol];
                if (forSymbol) {
                    newState.pendingOrderIdForSymbolCache[symbol] = insertOrderId(id, forSymbol, newState.ordersCache);
                }
                else {
                    newState.pendingOrderIdForSymbolCache[symbol] = [id];
                }
            }
            return newState;
        }
        case localTypes.GET_SEARCH_RESULTS_SUCCESS:
        case 'GET_STOCK_GROUP_SUCCESS': {
            const { payload: { assets }, } = action;
            if (assets.length) {
                const newCurrentPriceCache = { ...state.currentPriceCache };
                const newCache = { ...state.cache };
                assets.forEach((asset) => {
                    if (asset.icon) {
                        // adding extra check
                        const idObj = asset.id ? { id: asset.id } : {};
                        newCache[asset.symbol] = {
                            ...state.cache[asset.symbol],
                            iconUrl: asset.icon,
                            name: asset.name,
                            ...idObj,
                            ...(asset.description
                                ? {
                                    description: asset.description,
                                }
                                : {}), // TODO: make backend update the Asset type so that field is present there
                        };
                    }
                });
                let didChangePrices = false;
                const mTimestamp = getMTimestamp();
                assets.forEach((asset) => {
                    if (asset.sharePrice !== null && asset.sharePrice !== undefined) {
                        didChangePrices = true;
                        newCurrentPriceCache[asset.symbol] = {
                            value: asset.sharePrice,
                            currency: 'USD',
                            mTimestamp, // TODO: use timestamp from asset when it will be there (waiting for API update)
                        };
                    }
                });
                if (didChangePrices) {
                    return {
                        ...state,
                        cache: newCache,
                        currentPriceCache: newCurrentPriceCache,
                        priceChangeCache: updatePriceChangeCache(state.priceChangeCache, assets),
                    };
                }
                return { ...state, cache: newCache };
            }
            return state;
        }
        case localTypes.CANCEL_ORDER_SUCCESS: {
            const { extra: { orderId }, } = action;
            if (orderId) {
                // order is cancelled. Remove it from list of queued and pending orders (orderIdForSymbolCache, orderIdCache and their pending alternatives)
                let didSymbolQueuedCacheChange = false;
                let didQueuedCacheChange = false;
                let didSymbolPendingCacheChange = false;
                let didPendingCacheChange = false;
                let didCacheChange = false;
                const newOrderIdForSymbolCache = {
                    ...state.orderIdForSymbolCache,
                };
                const newPendingOrderIdForSymbolCache = {
                    ...state.pendingOrderIdForSymbolCache,
                };
                const newQueuedOrderIdCache = [...state.orderIdCache];
                const newPendingOrderIdCache = [...state.pendingOrderIdCache];
                // walk thru all symbols and look for this order, then remove it
                Object.keys(newOrderIdForSymbolCache).forEach((symbol) => {
                    const orders = newOrderIdForSymbolCache[symbol];
                    if (orders?.length) {
                        const index = orders.findIndex((nextOrderId) => nextOrderId === orderId);
                        if (index !== -1) {
                            orders.splice(index, 1);
                            newOrderIdForSymbolCache[symbol] = [...orders]; // need to update reference in order for selector to update
                            didSymbolQueuedCacheChange = true;
                        }
                    }
                });
                Object.keys(newPendingOrderIdForSymbolCache).forEach((symbol) => {
                    const orders = newPendingOrderIdForSymbolCache[symbol];
                    if (orders?.length) {
                        const index = orders.findIndex((nextOrderId) => nextOrderId === orderId);
                        if (index !== -1) {
                            orders.splice(index, 1);
                            newPendingOrderIdForSymbolCache[symbol] = [...orders]; // need to update reference in order for selector to update
                            didSymbolPendingCacheChange = true;
                        }
                    }
                });
                const index = newQueuedOrderIdCache.findIndex((nextOrderId) => nextOrderId === orderId);
                if (index !== -1) {
                    didQueuedCacheChange = true;
                    newQueuedOrderIdCache.splice(index, 1);
                }
                const indexForPending = newPendingOrderIdCache.findIndex((nextOrderId) => nextOrderId === orderId);
                if (indexForPending !== -1) {
                    didPendingCacheChange = true;
                    newPendingOrderIdCache.splice(indexForPending, 1);
                }
                const newState = { ...state };
                const cachedOrder = state.ordersCache[orderId];
                if (cachedOrder) {
                    didCacheChange = true;
                    newState.ordersCache = { ...state.ordersCache, [orderId]: undefined }; // remove this order from cache
                }
                if (didSymbolQueuedCacheChange) {
                    newState.orderIdForSymbolCache = newOrderIdForSymbolCache;
                }
                if (didQueuedCacheChange) {
                    newState.orderIdCache = newQueuedOrderIdCache;
                }
                if (didSymbolPendingCacheChange) {
                    newState.pendingOrderIdForSymbolCache = newPendingOrderIdForSymbolCache;
                }
                if (didPendingCacheChange) {
                    newState.pendingOrderIdCache = newPendingOrderIdCache;
                }
                if (didQueuedCacheChange ||
                    didSymbolQueuedCacheChange ||
                    didPendingCacheChange ||
                    didSymbolPendingCacheChange ||
                    didCacheChange) {
                    return newState;
                }
            }
            return state;
        }
        case 'SAVE_ONBOARDING_STATE': {
            const { screen, params } = action;
            const onboarding = { screen, params: { ...state.onboarding?.params, ...params } };
            return { ...state, onboarding };
        }
        case 'SAVE_INVEST_ACCOUNT_STATE': {
            return {
                ...state,
                hasFunded: action.hasFunded,
                hasTraded: action.hasTraded,
            };
        }
        case 'CACHE_TRUSTED_CONTACTS': {
            const { trustedContacts } = action;
            return { ...state, trustedContacts };
        }
        case 'SET_REFERRER_ID': {
            const { referrerId } = action;
            return { ...state, referrerId };
        }
        case 'SET_SECTORS': {
            return {
                ...state,
                sectorsCache: action.payload,
            };
        }
        case 'GET_LATEST_PRICE_INFO_SUCCESS': {
            const { payload } = action;
            const updatedPriceInfoCache = {};
            const updatedCurrentPriceCache = {};
            const mTimestamp = getMTimestamp();
            Object.keys(payload).forEach((symbol) => {
                updatedPriceInfoCache[symbol] = {
                    value: payload[symbol].priceChange,
                    percent: payload[symbol].priceChangePct,
                };
                updatedCurrentPriceCache[symbol] = {
                    mTimestamp,
                    value: payload[symbol].price,
                    currency: payload[symbol].currency,
                };
            });
            return {
                ...state,
                currentPriceCache: {
                    ...state.currentPriceCache,
                    ...updatedCurrentPriceCache,
                },
                priceChangeCache: {
                    ...state.priceChangeCache,
                    ...updatedPriceInfoCache,
                },
            };
        }
        /**
         * Adds a symbol to be shown in "Recently Viewed", cuts the array of symbols (if needed)
         * and removes any price cache for no longer needed symbols.
         */
        case 'ADD_RECENTLY_VIEWED_SYMBOL': {
            const { symbol } = action;
            const { recentlyViewedCache } = state;
            if (recentlyViewedCache?.[0] === symbol) {
                return state; // no need to change anything, user revisited the stock that he immediately visited before
            }
            const updatedArr = recentlyViewedCache ? [symbol, ...recentlyViewedCache] : [symbol];
            const uniqueArr = [...new Set(updatedArr)];
            if (uniqueArr.length <= RECENTLY_VIEWED_MAX_COUNT) {
                return {
                    ...state,
                    recentlyViewedCache: uniqueArr,
                };
            }
            const newRecentlyViewedCache = uniqueArr.slice(0, RECENTLY_VIEWED_MAX_COUNT);
            const symbolsNoLongerUsed = uniqueArr.slice(RECENTLY_VIEWED_MAX_COUNT, uniqueArr.length);
            const newPriceChangeCache = {
                ...state.priceChangeCache,
            };
            let didChangePriceCacheCopy = false;
            symbolsNoLongerUsed.forEach((symbol) => {
                if (symbol in newPriceChangeCache) {
                    delete newPriceChangeCache[symbol];
                    didChangePriceCacheCopy = true;
                }
            });
            if (didChangePriceCacheCopy) {
                return { ...state, priceChangeCache: newPriceChangeCache, recentlyViewedCache: newRecentlyViewedCache };
            }
            return { ...state, recentlyViewedCache: newRecentlyViewedCache };
        }
        case 'CREATE_WATCHLIST_SUCCESS': {
            const { payload: { watchlist }, } = action;
            const newWatchlistCache = {
                id: watchlist.id,
                name: watchlist.name,
                emoji: watchlist.emoji,
                assets: [],
            };
            const { watchlists: watchlistsCache } = state;
            if (watchlistsCache) {
                return { ...state, watchlists: [...watchlistsCache, newWatchlistCache] }; // TODO: ask backend about sorting - will the new list be appended to the end or is there any kind of sorting?
            }
            return { ...state, watchlists: [newWatchlistCache] };
        }
        case 'GET_WATCHLISTS_SUCCESS': {
            // TODO:add assets from api response which is in progress right now
            const { watchlists } = action.payload;
            const newWatchlistCache = watchlists.map((watchlist) => ({
                ...watchlist,
                assets: [],
            }));
            return {
                ...state,
                watchlists: newWatchlistCache,
            };
        }
        case 'UPDATE_WATCHLIST_SUCCESS': {
            /**
             * Updates watchlist's name and/or emoji
             */
            const { payload: { watchlist }, } = action;
            const { watchlists: watchlistsCache } = state;
            if (watchlistsCache) {
                const watchlistIndexInCache = watchlistsCache.findIndex((cachedItem) => cachedItem.id === watchlist.id);
                if (watchlistIndexInCache !== -1) {
                    // the watchlist that was edited was already cached. Update name and emoji from payload, and reset synced flag to true
                    return {
                        ...state,
                        watchlists: watchlistsCache.map((cachedItem, index) => {
                            if (index === watchlistIndexInCache) {
                                return {
                                    ...cachedItem,
                                    ...watchlist,
                                };
                            }
                            return cachedItem;
                        }),
                    };
                }
                // the watchlist that was edited was not even in cache yet. Add it
                const newWatchlistCache = {
                    id: watchlist.id,
                    name: watchlist.name,
                    emoji: watchlist.emoji,
                    assets: [],
                };
                return { ...state, watchlists: [...watchlistsCache, newWatchlistCache] }; // TODO: ask backend about sorting - will the new list be appended to the end or is there any kind of sorting?
            }
            const newWatchlistCache = {
                id: watchlist.id,
                name: watchlist.name,
                emoji: watchlist.emoji,
                assets: [],
            };
            return { ...state, watchlists: [newWatchlistCache] };
        }
        case 'DELETE_WATCHLIST_SUCCESS': {
            const { extra: { watchlistId }, } = action;
            const { watchlists: watchlistsCache } = state;
            if (watchlistsCache) {
                const watchlistIndexInCache = watchlistsCache.findIndex((cachedItem) => cachedItem.id === watchlistId);
                if (watchlistIndexInCache !== -1) {
                    return { ...state, watchlists: watchlistsCache.filter((cachedItem) => cachedItem.id !== watchlistId) };
                }
                // Deleted watchlist was not in cache already - do nothing.
                // In case when user tries to delete a watchlist that has not yet been synced with backend
                // (like he created the watchlist in an airplane) - there should be another action dispatched, with the offline id (of type number)
            }
            // if there was no cache, do nothing
            return state;
        }
        case 'GET_ASSET_INFO_SUCCESS': {
            const assetInfo = action.payload;
            const symbols = Object.keys(assetInfo);
            if (!symbols) {
                return state;
            }
            const stockMetaCache = {};
            symbols.forEach((symbol) => {
                const assetInfoForSymbol = assetInfo[symbol];
                if (!assetInfoForSymbol)
                    return;
                // Adding extra check
                const idObj = assetInfoForSymbol.id ? { id: assetInfoForSymbol.id } : {};
                stockMetaCache[symbol] = {
                    ...state.cache[symbol],
                    ...idObj,
                    name: assetInfoForSymbol.name,
                    iconUrl: assetInfoForSymbol.icon,
                    ...assetInfoForSymbol.info,
                };
            });
            return {
                ...state,
                cache: {
                    ...state.cache,
                    ...stockMetaCache,
                },
            };
        }
        case 'GET_TOP_TRENDING_STOCKS_SUCCESS': {
            const { payload: { assets }, } = action;
            return {
                ...state,
                cache: updateStockMetaCache(state.cache, assets),
                currentPriceCache: updateCurrentPriceCache(state.currentPriceCache, assets),
                priceChangeCache: updatePriceChangeCache(state.priceChangeCache, assets),
                topTrending: assets.map((asset) => asset.symbol),
            };
        }
        case 'GET_MOST_WATCHED_STOCKS_SUCCESS': {
            const { payload: { assets }, } = action;
            return {
                ...state,
                cache: updateStockMetaCache(state.cache, assets),
                mostWatchedStocks: assets.map((asset) => asset.symbol),
                priceChangeCache: updatePriceChangeCache(state.priceChangeCache, assets),
                currentPriceCache: updateCurrentPriceCache(state.currentPriceCache, assets),
            };
        }
        case 'GET_MOST_HELD_STOCKS_SUCCESS': {
            const { payload: { assets }, } = action;
            return {
                ...state,
                cache: updateStockMetaCache(state.cache, assets),
                mostHeldStocks: assets.map((asset) => asset.symbol),
                priceChangeCache: updatePriceChangeCache(state.priceChangeCache, assets),
                currentPriceCache: updateCurrentPriceCache(state.currentPriceCache, assets),
            };
        }
        case 'GET_RECENTLY_ADDED_STOCKS_SUCCESS': {
            const { payload: { assets }, } = action;
            return {
                ...state,
                cache: updateStockMetaCache(state.cache, assets),
                currentPriceCache: updateCurrentPriceCache(state.currentPriceCache, assets),
                priceChangeCache: updatePriceChangeCache(state.priceChangeCache, assets),
                newlyAdded: assets.map((asset) => asset.symbol),
            };
        }
        case 'GET_WATCHLIST_SUCCESS': {
            const { assets } = action.payload.watchlist;
            const updatedMetaCache = {};
            const updatedCurrentPrice = {};
            const updatedPriceChange = {};
            const mTimestamp = getMTimestamp();
            assets.forEach((asset) => {
                const { symbol, sharePrice, sharePriceCurrency, sharePriceChange, sharePriceChangePct, name, icon, id } = asset;
                if (sharePrice && sharePriceCurrency) {
                    updatedCurrentPrice[symbol] = {
                        mTimestamp,
                        value: sharePrice,
                        currency: sharePriceCurrency,
                    };
                }
                if (name && icon) {
                    updatedMetaCache[symbol] = {
                        ...state.cache[symbol],
                        id,
                        name,
                        iconUrl: icon,
                    };
                }
                if (sharePriceChange && sharePriceChangePct) {
                    updatedPriceChange[symbol] = {
                        value: sharePriceChange,
                        percent: sharePriceChangePct,
                    };
                }
            });
            return {
                ...state,
                cache: {
                    ...state.cache,
                    ...updatedMetaCache,
                },
                currentPriceCache: {
                    ...state.currentPriceCache,
                    ...updatedCurrentPrice,
                },
                priceChangeCache: {
                    ...state.priceChangeCache,
                    ...updatedPriceChange,
                },
            };
        }
        case 'GET_PENDING_TOPUPS_SUCCESS': {
            const { payload } = action;
            return { ...state, pendingTopupsFlag: payload.pendingTopUps.length > 0 };
        }
        case 'TOPUP_CREATED': {
            return { ...state, pendingTopupsFlag: true };
        }
        case 'GET_REQUIRED_NATIONAL_IDENTIFIERS_SUCCESS': {
            const requiredNationalityIdentifiersWithCountry = action.payload.requiredNationalityIdentifiers.map((identifier) => ({
                ...identifier,
                country: action.payload.nationality,
            }));
            return {
                ...state,
                requiredNationalityIdentifiers: requiredNationalityIdentifiersWithCountry,
            };
        }
        case 'POST_REQUIRED_NATIONAL_IDENTIFIERS_SUCCESS': {
            const { type, value } = action.extra;
            if (value) {
                return {
                    ...state,
                    requiredNationalityIdentifiers: [],
                };
            }
            const filteredArr = state.requiredNationalityIdentifiers?.filter((identifier) => identifier.type !== type) || [];
            return {
                ...state,
                requiredNationalityIdentifiers: filteredArr,
            };
        }
        default: {
            return state;
        }
    }
};
export default reducer;
