import React, { useLayoutEffect } from 'react';
import LinearGradient from 'react-native-linear-gradient';
import { headerHeight, rem } from 'design-system/values';
import { StatusBar, StyleSheet, View } from 'react-native';
import Text from 'design-system/Text';
import { IconSquareCircle } from 'design-system/icons';
import useColors from 'hooks/useColors';
import useColorScheme from 'hooks/useColorScheme';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { useDefaultHeaderHeight } from 'hooks/useHeaderHeight';
import { AppView } from './AppView';
/**
 * Figma: https://www.figma.com/file/SMqeFyVH6qLbMzeoCHBp4A/Emma-Design-System?node-id=4195%3A122415
 */
export const RequestActionView = ({ title, renderTitle, subTitle, icon, iconScale = 4.6, renderIcon, renderSubTitle, children, primary, }) => {
    const colors = useColors();
    const iconNode = renderIcon?.();
    const navigation = useMainStackNavigation();
    useLayoutEffect(() => {
        navigation.setOptions({
            headerTintColor: (primary
                ? colors.text.primary
                : colors.text.white),
        });
    }, [colors.text.primary, colors.text.white, primary]);
    const titleColor = primary ? { OffBlack: true } : { White: true };
    const subTitleColor = primary
        ? { Secondary: true }
        : { White: true };
    const content = (<>
      {iconNode ||
            (Boolean(icon) && (<View style={styles.iconContainer}>
            <IconSquareCircle color={primary ? colors.cards.onLight : colors.background.transparent} scale={iconScale}>
              {icon}
            </IconSquareCircle>
          </View>))}
      <View>
        <View style={styles.titleContainer}>
          {renderTitle ? (renderTitle()) : (<Text Text-24 {...titleColor} centered>
              {title}
            </Text>)}
        </View>
        {renderSubTitle ? (renderSubTitle()) : (<Text TextThin-16 centered {...subTitleColor}>
            {subTitle}
          </Text>)}
      </View>
    </>);
    const colorScheme = useColorScheme();
    const { paddingHorizontal } = useAppFrameDimensions();
    const defaultHeaderHeight = useDefaultHeaderHeight();
    if (primary) {
        return (<>
        <StatusBar translucent animated backgroundColor="transparent" barStyle={colorScheme === 'dark' ? 'light-content' : 'dark-content'}/>
        <AppView type="primary" style={[
                styles.content,
                {
                    paddingHorizontal: (paddingHorizontal || 0) + rem(36),
                    marginTop: -defaultHeaderHeight,
                },
            ]}>
          {content}
          {children}
        </AppView>
      </>);
    }
    return (<>
      <StatusBar translucent animated backgroundColor="transparent" barStyle="light-content"/>
      <LinearGradient {...colors.gradients.brandGradient} style={[styles.content, { marginTop: -headerHeight }]}>
        {content}
        {children}
      </LinearGradient>
    </>);
};
const styles = StyleSheet.create({
    content: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: rem(36),
        marginTop: -headerHeight,
    },
    titleContainer: {
        marginBottom: rem(8),
    },
    iconContainer: {
        marginBottom: rem(18),
    },
});
