import React, { useCallback, useLayoutEffect, useState } from 'react';
import { View } from 'react-native';
import { CommonActions } from '@react-navigation/native';
import Modal from 'design-system/ModalWeb';
import Button from 'design-system/Button';
import Spacer from 'design-system/Spacer';
import { AppView } from 'design-system/AppView';
import { Indicator } from 'design-system/Indicator';
import { setSetupPasscode, setPasscode } from 'actions';
import { muteErrorAndResult, useFetchOld } from 'hooks';
import { usePasscodeVerifier } from 'hooks/usePasscodeVerifier';
import createStyleSheets from 'utils/createStyleSheets';
import useStyles from 'hooks/useStyles';
import usePrivateRoute from 'hooks/usePrivateRoute';
import useColors from 'hooks/useColors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import WebPasscode from 'common/passcode/WebPasscode';
import { selectUserId } from 'reducers/selectors';
const strings = {
    currentTitle: 'Enter your current code',
    setTitle: 'Choose a passcode',
    confirmTitle: 'Repeat passcode',
    setDescription: 'You will use this to access the app',
    wrongPasscode: 'Your passcode is wrong',
    notMatch: 'Your second attempt failed :(',
    errormsg: 'The passcode you entered is not correct',
    errormsgSet: 'The passcode you entered does not match',
};
const ChangePasscode = ({ navigation }) => {
    usePrivateRoute();
    const dispatch = useAppDispatch();
    const [, , fetching, fetch] = useFetchOld(muteErrorAndResult);
    const styles = useStyles(styleSet);
    const userId = useAppSelector(selectUserId);
    const [step, setStep] = useState(1);
    const [oldPasscode, setOldPasscode] = useState('');
    const [newPasscode, setNewPasscode] = useState('');
    const [confirmPasscode, setConfirmPasscode] = useState('');
    const isValidInput = (step === 1 && oldPasscode.length === 4) ||
        (step === 2 && newPasscode.length === 4) ||
        (step === 3 && confirmPasscode.length === 4);
    const [passCodeFailed, setPasscodeFailed] = useState(false);
    const [matchCodeFailed, setMatchCodeFailed] = useState(false);
    const [passcodeVerifier] = usePasscodeVerifier();
    const colors = useColors();
    useLayoutEffect(() => {
        navigation.setOptions({
            headerTitle: '',
            title: 'Change Passcode',
            headerTintColor: colors.text.primary,
        });
    }, [colors.text.primary]);
    const goBack = useCallback((count = 1) => {
        if (navigation.canGoBack() && navigation.getState().routes.length > 2) {
            navigation.pop(count);
        }
        else {
            navigation.dispatch(CommonActions.reset({
                index: 0,
                routes: [{ name: 'Feed' }],
            }));
        }
    }, []);
    const onContinue = useCallback(async () => {
        setPasscodeFailed(false);
        setMatchCodeFailed(false);
        if (step === 1) {
            try {
                const verifyResult = await passcodeVerifier(oldPasscode, true);
                if (verifyResult || verifyResult === null) {
                    setStep(2);
                }
                else {
                    setOldPasscode('');
                    setPasscodeFailed(true);
                }
            }
            catch (e) {
                Modal.showError('Could not verify passcode');
            }
        }
        else if (step === 2) {
            dispatch(setSetupPasscode());
            setStep(3);
        }
        else if (step === 3) {
            if (newPasscode === confirmPasscode) {
                const setPassCodeResult = await fetch(setPasscode(confirmPasscode, oldPasscode, true));
                if (setPassCodeResult) {
                    navigation.navigate('Success', {
                        title: 'Passcode changed!',
                        subTitle: 'Your passcode has been successfully changed.',
                        onContinue: () => {
                            goBack(2);
                            return true;
                        },
                    });
                }
            }
            else {
                setMatchCodeFailed(true);
                setStep(2);
                setNewPasscode('');
                setConfirmPasscode('');
            }
        }
    }, [
        confirmPasscode,
        goBack,
        newPasscode,
        oldPasscode,
        passcodeVerifier,
        step,
    ]);
    const passcodeSection = () => {
        if (step === 1) {
            return (<WebPasscode value={oldPasscode} setValue={setOldPasscode} failed={passCodeFailed} errorMessage={strings.errormsg} title={strings.currentTitle}/>);
        }
        if (step === 2) {
            return (<WebPasscode value={newPasscode} setValue={setNewPasscode} failed={matchCodeFailed} errorMessage={strings.errormsgSet} title={strings.setTitle} subTitle={strings.setDescription}/>);
        }
        if (step === 3) {
            return (<WebPasscode value={confirmPasscode} setValue={setConfirmPasscode} title={strings.confirmTitle} subTitle={strings.setDescription}/>);
        }
        return null;
    };
    if (userId === -1) {
        return (<AppView isInTab style={styles.container}>
        <Indicator dark/>
      </AppView>);
    }
    return (<AppView avoidHeader isInTab style={styles.container}>
      <View>{passcodeSection()}</View>

      <View style={styles.buttons}>
        <Button transparent onPress={() => goBack()} title="Cancel" style={styles.cancelButton}/>
        <Button disabled={!isValidInput} isFetching={fetching} brand onPress={onContinue} title="Continue"/>
        <Spacer h={16}/>
      </View>
    </AppView>);
};
export default ChangePasscode;
const styleSet = createStyleSheets((colors) => ({
    container: { marginTop: 20, justifyContent: 'space-between' },
    cell: {
        width: 56,
        height: 56,
        borderRadius: 12,
        padding: 8,
        fontSize: 24,
        borderWidth: 2,
        borderColor: 'transparent',
        backgroundColor: colors.cards.onDark,
        textAlign: 'center',
    },
    focusCell: {
        borderColor: colors.text.brand,
    },
    buttons: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    cancelButton: {
        marginBottom: 8,
        color: colors.text.textOnBrand,
    },
}));
