import { FlashList } from '@shopify/flash-list';
import moment from 'moment';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState, } from 'react';
import Animated from 'react-native-reanimated';
import { CommonActions } from '@react-navigation/native';
import { useWindowDimensions } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Button, { HEIGHT } from 'design-system/Button';
import FlatListItemCard from 'design-system/FlatListItemCard';
import withAnimatedNavigationHeader from 'design-system/hoc/withAnimatedNavigationHeader';
import Indicator from 'design-system/Indicator';
import ListEmptyView from 'design-system/ListEmptyView';
import StatusBar from 'design-system/StatusBar';
import { rem, SCROLL_INDICATOR_INSETS } from 'design-system/values';
import { AppView } from 'design-system/AppView';
import StackHeader from 'design-system/hoc/StackHeader';
import InfoCard from 'design-system/InfoCard';
import Text from 'design-system/Text';
import createStyleSheets from 'utils/createStyleSheets';
import Links, { knownLinks } from 'utils/links';
import RowTransaction, { ESTIMATED_ITEM_SIZE, } from 'features/transactions/components/RowTransaction';
import useAdvancedEditTransactions from 'features/transactions/hooks/useAdvancedEditTransactions';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import { getTransactionsFlat, } from 'utils/getTransactions';
import SwitchingButton from 'features/subscriptions/components/row/SwitchingButton';
import ExcludedInfoModalContent from 'features/transactions/components/categories/ExcludedInfoModalContent';
import { useHeaderHeight } from 'hooks';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import SectionHeader from 'features/invest/components/SectionHeader';
import useShouldShowUpgrade from 'features/premium/hooks/useShouldShowUpgrade';
import TransactionsHeader from 'features/transactions/components/TransactionsHeader';
import { useMarginBottom } from 'hooks/useMarginBottom';
import { addTransactionToCache } from 'actions';
import usePrivateRoute from 'hooks/usePrivateRoute';
import Modal from 'utils/packages/Modal';
import Amplitude from '../../../utils/amplitude';
import CategoryTopContainer from '../components/category/CategoryTopContainer';
import useSyncMonthlyExpenses from '../hooks/useSyncMonthlyExpenses';
import { isWeb } from '../../../constants';
import { selectAnalyticsPosition, selectMonthlyCategoryFromCategoryId, selectMonthlyMerchantFromMerchantId, selectMonthlyTotals, selectMonthlyTotalsFilteredWithFallbackMonths, } from '../selectors';
import { mapStepToMomentUnit } from '../hooks/useOpenCategory';
const getItemType = (item) => item.type;
const keyExtractor = (item) => item.type === 'header' ? item.title : item.data.id.toString();
const selectIsFetchingCategoryExpenses = (store) => store.expenses.isFetchingCategoryExpenses;
const AnimatedFlashList = Animated.createAnimatedComponent(FlashList);
const handleLearnMore = () => {
    Links.openLink(knownLinks.unknownMerchants);
};
const CategoryDetailsScreen = withAnimatedNavigationHeader(({ navigation, route, scrollY, scrollHandler }) => {
    usePrivateRoute();
    const colors = useColors();
    const styles = useStyles(styleSet);
    const unfilteredMonthlyTotals = useAppSelector(selectMonthlyTotals);
    const filteredMonthlyTotals = useAppSelector(selectMonthlyTotalsFilteredWithFallbackMonths);
    const position = useAppSelector(selectAnalyticsPosition);
    const { selected, isEditing, selectedObj, onEdit, onSelected, openEditModal, } = useAdvancedEditTransactions(navigation);
    const guessedHomeCountry = useAppSelector((store) => store.user.user.guessedHomeCountry);
    const monthlyTotals = filteredMonthlyTotals?.results || unfilteredMonthlyTotals;
    const latestToDate = monthlyTotals?.[0]?.to;
    const data = useMemo(() => {
        if (route.params?.to &&
            route.params?.from &&
            (route.params?.merchantId || route.params?.categoryId)) {
            const { to, from } = route.params;
            let toDate = moment(route.params.to);
            let fromDate = moment(route.params.from);
            const unit = mapStepToMomentUnit(filteredMonthlyTotals?.step);
            if (filteredMonthlyTotals?.step !== 'custom') {
                if (position === 0) {
                    fromDate = fromDate.subtract(6, unit).startOf(unit);
                }
                else {
                    toDate = moment(to).add(3, unit).startOf(unit);
                    fromDate = moment(from).subtract(3, unit).startOf(unit);
                    if (moment(to).add(3, unit).diff(moment()) > 0) {
                        toDate = moment(latestToDate);
                    }
                }
            }
            return {
                step: route.params?.step,
                monthEndDate: route.params.to,
                to: toDate.format('YYYY-MM-DD'),
                monthStartDate: route.params.from,
                from: fromDate.format('YYYY-MM-DD'),
                fromCurrentMonth: route.params.from,
                merchantId: route.params?.merchantId,
                categoryId: route.params?.categoryId,
                accountIds: route.params?.accountIds,
            };
        }
        return undefined;
    }, [
        position,
        filteredMonthlyTotals?.step,
        latestToDate,
        route.params?.step,
        route.params?.to,
        route.params?.from,
        route.params?.merchantId,
        route.params?.categoryId,
        route.params?.accountIds,
    ]);
    const category = useAppSelector((store) => {
        if (data?.categoryId) {
            return selectMonthlyCategoryFromCategoryId?.(store, data.categoryId);
        }
        if (data?.merchantId) {
            return selectMonthlyMerchantFromMerchantId?.(store, data.merchantId);
        }
        return undefined;
    });
    useEffect(() => {
        if (!category && isWeb) {
            navigation.dispatch(CommonActions.reset({
                index: 0,
                routes: [{ name: 'Analytics' }],
            }));
        }
    }, [category]);
    useLayoutEffect(() => {
        if (category) {
            navigation.setOptions({
                headerRight: () => (<TransactionsHeader isEditing={isEditing} onPressEdit={onEdit}/>),
                headerTitle: () => (<StackHeader width={250} title={category?.displayName}/>),
                title: category.displayName,
            });
        }
    }, [category, isEditing, onEdit]);
    const [{ selectedPeriodStartDate }, setSelectedPeriod] = useState({
        selectedPeriodStartDate: data?.monthStartDate,
        selectedPeriodEndDate: data?.monthEndDate,
    });
    const [, categoryExpenses] = useSyncMonthlyExpenses(data);
    const isFetchingCategoryExpenses = useAppSelector(selectIsFetchingCategoryExpenses);
    useEffect(() => {
        let timeout;
        const categoryId = data?.categoryId;
        if (categoryId) {
            const checkExcluded = async () => {
                if (categoryId !== 'internal')
                    return;
                try {
                    const value = await AsyncStorage.getItem('FIRST_TIME_EXCLUDED');
                    if (value === null) {
                        timeout = setTimeout(() => {
                            Modal.show(<ExcludedInfoModalContent closeModal={() => {
                                    Modal.hide();
                                }}/>, {
                                title: 'Excluded',
                                containerStyle: styles.modal,
                            });
                        });
                        await AsyncStorage.setItem('FIRST_TIME_EXCLUDED', 'true');
                    }
                }
                catch (error) {
                    // Error retrieving data
                }
            };
            checkExcluded();
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [data?.categoryId, route.params, styles.modal]);
    const onCompare = useCallback((routeWithParams) => {
        navigation.navigate(routeWithParams);
        Amplitude.logEvent('Category.openCompare', {
            type: routeWithParams.name,
        });
    }, []);
    const { isUnlockedForSpace: isSearchUnlockedForSpace, onPressUpgrade } = useShouldShowUpgrade({
        benefitId: 'search',
    });
    const twoMonthsAgo = useMemo(() => moment().subtract(2, 'months'), []);
    const isLocked = useMemo(() => {
        if (isSearchUnlockedForSpace) {
            return false;
        }
        return moment(selectedPeriodStartDate).isBefore(twoMonthsAgo);
    }, [isSearchUnlockedForSpace, selectedPeriodStartDate, twoMonthsAgo]);
    const dispatch = useAppDispatch();
    const openItem = useCallback((data) => {
        if (isLocked) {
            onPressUpgrade();
            return;
        }
        const { isPending } = data;
        if (isEditing) {
            if (isPending)
                return;
            onSelected(data);
            return;
        }
        dispatch(addTransactionToCache(data));
        navigation.navigate('Item', {
            id: data.id,
        });
        Amplitude.logEvent('Category.OpenTransaction');
    }, [isEditing, isLocked, onPressUpgrade, onSelected]);
    const renderRow = useCallback(({ item }) => {
        if (item.type === 'header') {
            let title = null;
            if (item.title !== 'Pending transactions') {
                const date = moment(item.title);
                if (moment().year() === date.year()) {
                    title = date.format('dddd, D MMMM');
                }
                else {
                    title = date.format('dddd, D MMMM YYYY');
                }
            }
            else {
                title = 'Pending transactions';
            }
            return <SectionHeader title={title}/>;
        }
        return (<FlatListItemCard index={item.sectionIndex} style={styles.card} itemsLength={item.sectionLength}>
            <RowTransaction item={item.data} onPress={openItem} showUpgradeButton backgroundDark switchingButon={<SwitchingButton data={
                // Add merchandInfo if coming from SearchTransactions
                !item.data.subscription && item && item.data.merchant
                    ? { merchantInfo: item.data.merchant }
                    : item.data.subscription} onOpenCompare={onCompare} guessedHomeCountry={guessedHomeCountry}/>} blurValue={isLocked} isEditingTransactions={isEditing} selected={!!selectedObj[item.data.id]}/>
          </FlatListItemCard>);
    }, [
        guessedHomeCountry,
        isEditing,
        isLocked,
        onCompare,
        openItem,
        selectedObj,
        styles.card,
    ]);
    const { paddingHorizontalStyle } = useAppFrameDimensions(isWeb);
    const renderListHeader = useMemo(() => {
        if (data && category) {
            if (category.id === -1) {
                return (<InfoCard backgroundColor={colors.cards.onDark} gradientKey="ultimate" onPress={handleLearnMore} style={styles.infoCard}>
              <Text TextThin-14 Secondary>
                These transactions have not been matched with a merchant.{' '}
                <Text Text-14 Primary>
                  Learn more
                </Text>
              </Text>
            </InfoCard>);
            }
            return (<CategoryTopContainer scrollY={scrollY} to={data?.to} step={data?.step} categoryId={data?.categoryId} merchantId={data?.merchantId} accountIds={data?.accountIds} defaultAmount={category.total} defaultTo={data?.monthEndDate} defaultFrom={data?.monthStartDate} onSelectedPeriod={setSelectedPeriod} initialPositionDate={data?.fromCurrentMonth}/>);
        }
        return null;
    }, [category, colors.cards.onDark, data, scrollY, styles.infoCard]);
    const renderListEmpty = useMemo(() => (<ListEmptyView style={styles.empty} title="There are no transactions" happy/>), [styles.empty]);
    const transactionSections = useMemo(() => {
        if (isFetchingCategoryExpenses) {
            return [];
        }
        return getTransactionsFlat(categoryExpenses);
    }, [categoryExpenses, isFetchingCategoryExpenses]);
    const headerHeight = useHeaderHeight();
    const { height, width } = useWindowDimensions();
    const estimatedListSize = useMemo(() => ({ height: height - headerHeight, width }), [headerHeight, height, width]);
    const isEditButtonVisible = isEditing && selected.length !== 0;
    const contentContainerStyle = useMarginBottom('paddingBottom', isEditButtonVisible ? HEIGHT + rem(16) : 0, paddingHorizontalStyle);
    const extraData = useMemo(() => [colors.cards.onDark, isEditing, isLocked, selectedObj], [colors.cards.onDark, isEditing, isLocked, selectedObj]);
    const baseProps = {
        extraData,
        renderItem: renderRow,
        scrollEventThrottle: 8,
        onScroll: scrollHandler,
        data: transactionSections,
        onEndReachedThreshold: 0.8,
        keyExtractor,
        ListEmptyComponent: renderListEmpty,
        ListHeaderComponent: renderListHeader,
        contentContainerStyle,
        scrollIndicatorInsets: SCROLL_INDICATOR_INSETS,
    };
    const list = isWeb ? (<Animated.FlatList {...baseProps}/>) : (<AnimatedFlashList {...baseProps} estimatedListSize={estimatedListSize} estimatedItemSize={ESTIMATED_ITEM_SIZE} getItemType={getItemType}/>);
    return (<AppView withFrame={false}>
        <StatusBar barStyle="dark-content" animated/>
        {isFetchingCategoryExpenses ? <Indicator dark/> : list}
        {isEditButtonVisible && (<Button brand floating title={`Edit (${selected.length}) Transactions`} onPress={openEditModal}/>)}
      </AppView>);
}, ({ colors }) => ({
    hasCustomTitle: true,
    headerColorOutputRange: [
        colors.background.dark,
        colors.cards.onDark,
    ],
}));
const styleSet = createStyleSheets((colors) => ({
    empty: {
        marginTop: rem(32),
    },
    modal: {
        paddingHorizontal: 0,
        overflow: 'hidden',
    },
    card: {
        backgroundColor: colors.cards.onDark,
    },
    infoCard: {
        marginTop: rem(16),
    },
}));
export default CategoryDetailsScreen;
