import emoji from 'node-emoji';
import { apiEvents } from 'features/pay/constants';
import { logout } from 'actions';
import * as actionsTypes from '../actions/types';
import Sentry from '../utils/sentry';
import { VERIFY_PASSCODE_FAILURE } from '../actions/types';
import { isWeb } from '../constants';
// NB: by default we hide the modal for all GET requests.
const ERRORS_HIDE_MODAL = [
    actionsTypes.ON_REPORT_FINISHED_FAILURE,
    actionsTypes.ON_REPORT_OPENED_FAILURE,
    actionsTypes.ON_REPORT_SWIPED_FAILURE,
    actionsTypes.POST_ANSWER_FAILURE,
    actionsTypes.SET_APPSFLYER_FAILURE,
    actionsTypes.SET_PUSH_FAILURE,
    actionsTypes.DELETE_AUTOMATION_RULES_FAILURE,
    actionsTypes.LINK_NUMBER_VERIFY_CODE_FAILURE,
    actionsTypes.VERIFY_CODE_FAILURE,
    apiEvents.paymentSend[2],
    apiEvents.paymentRequestFulfill[2],
];
// TODO: Provide more precise types
export const errorMiddleware = () => (next) => async (action) => {
    if (action &&
        (action.errorType === 'ApiError' || action.errorType === 'FetchError')) {
        const { headers, ok, status, statusText, url } = action.payload || {};
        const { request, type } = action;
        const errorParams = {
            apiPayload: { headers, ok, status, statusText, type, url },
            type: action.type,
            errorName: undefined,
            error: 'Error',
            extra: action.extra,
            errorMessage: 'This is awkward, please try again.',
            errorType: 'ApiError',
            errorDisplayModal: (action.method !== 'GET' &&
                !ERRORS_HIDE_MODAL.includes(action.type) &&
                !action.errorHideModal &&
                !action.muteAlert) ||
                action.muteAlert === false,
        };
        if (action.errorType === 'FetchError') {
            errorParams.error = 'No connection';
            errorParams.errorMessage =
                'Please check your network connection and try again';
        }
        switch (status) {
            case 400: // expired access token or BadRequestError
                try {
                    const message = await action.payload.json();
                    if (message?.user_message) {
                        errorParams.errorMessage = message.user_message;
                    }
                    else if (message?.userMessage) {
                        errorParams.errorMessage = message.userMessage;
                    }
                }
                catch {
                    // noop
                }
                break;
            case 401: // expired access token
                break;
            case 403:
                // invalid refresh token
                try {
                    const message = await action.payload.json();
                    if (message?.error_description === 'Invalid refresh token') {
                        errorParams.error = 'Session expired';
                        errorParams.errorMessage = emoji.emojify('Your session has expired. Please login again. :pray:');
                        errorParams.errorType = 'SessionExpired';
                        errorParams.requestTimestamp =
                            headers?.requestTimestamp || String(new Date().getTime());
                        errorParams.errorDisplayModal = true;
                    }
                    else if (message?.error === 'server_error') {
                        errorParams.errorMessage = message.error_description;
                        errorParams.errorDisplayModal = true;
                    }
                    else {
                        if (message?.error.name === 'ForbiddenError') {
                            errorParams.errorType = 'ForbiddenError';
                        }
                        if (message?.user_message) {
                            errorParams.errorMessage = message.user_message;
                        }
                        else if (message?.userMessage) {
                            errorParams.errorMessage = message.userMessage;
                        }
                    }
                }
                catch (e) {
                    // No op
                    log('Cound not handle 403 refresh token', false, 'red');
                }
                break;
            case 404:
                try {
                    const message = await action.payload.json();
                    if (message?.error?.name === 'SubscriptionNotFoundError') {
                        errorParams.error = 'Please wait';
                        errorParams.errorMessage = message.error.userMessage;
                    }
                    else if (message?.error?.name === 'UserNotFoundError') {
                        errorParams.error = 'User not found';
                        errorParams.errorMessage =
                            "Please go back and sign up if you don't already have an account.";
                    }
                    else if (message?.user_message) {
                        errorParams.errorMessage = message.user_message;
                    }
                    else if (message?.userMessage) {
                        errorParams.errorMessage = message.userMessage;
                    }
                }
                catch (e) {
                    // No op
                }
                break;
            case 409:
            case 422:
            case 500:
            case 502:
                try {
                    const message = await action.payload.json();
                    if (message) {
                        errorParams.errorMessage = message.user_message;
                        errorParams.errorName = message.error?.name;
                        // Oauth endpoint returns errors in the format:
                        // {
                        //   "error": "invalid_grant",
                        //   "error_description": "Verification code is incorrect"
                        // }
                        if (errorParams.errorMessage === undefined) {
                            errorParams.errorMessage = message.error_description;
                            errorParams.errorName = message.error;
                        }
                    }
                }
                catch (e) {
                    // No op
                }
                break;
            case 503:
                try {
                    errorParams.errorType = 'ServerDown';
                    errorParams.errorDisplayModal = false;
                    const message = await action.payload.json();
                    if (message) {
                        errorParams.errorMessage = message.user_message;
                    }
                }
                catch (e) {
                    // No op
                }
                break;
            default:
                break;
        }
        Sentry.logNetworkError({
            url,
            method: action.method,
            status,
            error: errorParams.error,
            errorMessage: errorParams.errorMessage,
            requestId: request || type,
        });
        return next(errorParams);
    }
    if (action?.errorType === 'SetError' ||
        action?.errorType === 'SessionExpired') {
        if ((action?.type === VERIFY_PASSCODE_FAILURE ||
            isWeb ||
            action.muteAlertForToken) &&
            action?.errorType === 'SessionExpired') {
            return next(logout());
        }
        return next({
            type: action.type,
            extra: action.extra,
            error: action.error || 'Error',
            requestTimestamp: action?.requestTimestamp,
            errorMessage: action.errorMessage || 'This is awkward, please try again.',
            errorType: action.errorType,
            errorDisplayModal: !ERRORS_HIDE_MODAL.includes(action.type),
        });
    }
    return next(action);
};
