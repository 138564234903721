import { createSelector } from 'reselect';
import { Tier } from 'features/premium/entities';
import { selectUserTier } from 'features/premium/selectors/tier';
import { assertUnreachable } from 'utils/types';
import { AssetFilterCategory, } from '../types';
import * as debug from '../debug';
import { filterDataByKey } from '../utils/format';
export const selectAccountStatus = (state) => debug.fakeStatus || (state.invest.account ? state.invest.account.status : state.invest.status);
export function selectWatchlists(state) {
    return state.invest.watchlists;
}
export const selectRecurringBuys = (state) => state.unpersistedInvestReducer.recurringBuys;
/**
 * Selects the array of symbols that are top trending during the current session.
 */
export const selectTopTrending = (state) => state.invest.topTrending;
/**
 * Selects the array of symbols that are most watched during the current session.
 */
export const selectMostWatchedStocks = (state) => state.invest.mostWatchedStocks;
/**
 * Selects the array of symbols that are most held during the current session.
 */
export const selectMostHeldStocks = (state) => state.invest.mostHeldStocks;
/**
 * Selects the array of symbols that are newly added.
 */
export const selectNewlyAdded = (state) => state.invest.newlyAdded;
/**
 * Selects the array of symbols that fell down in price the most during the current session.
 */
export const selectLosers = (state) => state.invest.losers;
/**
 * Selects the array of symbols that went up in price the most during the current session.
 */
export const selectGainers = (state) => state.invest.gainers;
/**
 * Selects the watchListAssetCache from watchList reducer.
 */
export const selectWatchListAssetCache = (state) => state.watchList.watchListAssetCache;
/**
 * Selects the currentPriceCache from invest reducer.
 */
export const selectCurrentPriceCache = (state) => state.invest.currentPriceCache;
/**
 * Selects the currentPriceChangeCache from invest reducer.
 */
export const selectPriceCache = (state) => state.invest.priceChangeCache;
/**
 * Selects the array of AssetWithPrice that is sorted according to the selected watchList filter(AssetFilterCategory).
 */
export const selectAssetsForWatchListFilter = createSelector([
    selectWatchListAssetCache,
    (_state, watchListId, filter) => ({
        watchListId,
        filter,
    }),
    selectCurrentPriceCache,
    selectPriceCache,
], (watchListAssetCache, { filter, watchListId }, currentPrices, priceChanges) => {
    const watchListAssetsForWatchListId = watchListAssetCache?.[watchListId] || [];
    if (filter === AssetFilterCategory.NAME) {
        // watchListAssetCache is already sorted alphabetically by name
        return watchListAssetsForWatchListId;
    }
    const assets = [];
    watchListAssetsForWatchListId.forEach((asset) => {
        const currentPrice = currentPrices?.[asset.symbol];
        const priceChange = priceChanges?.[asset.symbol];
        if (currentPrice && priceChange) {
            assets.push({
                ...asset,
                sharePrice: currentPrice.value,
                sharePriceChange: priceChange.value,
                sharePriceChangePct: priceChange.percent,
            });
        }
    });
    if (filter === AssetFilterCategory.SYMBOL) {
        // sort by symbol
        assets.sort((a, b) => {
            if (a.symbol < b.symbol) {
                return -1;
            }
            if (a.symbol > b.symbol) {
                return 1;
            }
            return 0;
        });
        return assets;
    }
    if (filter === AssetFilterCategory.PRICE) {
        // sort by price
        assets.sort((a, b) => b.sharePrice - a.sharePrice);
        return assets;
    }
    if (filter === AssetFilterCategory.PRICE_CHANGE) {
        // sort by priceChangePct
        assets.sort((a, b) => b.sharePriceChangePct - a.sharePriceChangePct);
        return assets;
    }
    assertUnreachable(filter);
    return [];
});
export const selectSlicedLearnVideos = createSelector([(state) => state.utils.featureFlags, (_state, videoIndex) => videoIndex], (featureFlags, videoIndex) => {
    const learnVideos = featureFlags.invest_educational_videos?.extra?.videos;
    if (learnVideos?.length) {
        return learnVideos.slice(videoIndex, learnVideos.length);
    }
    return [];
});
/**
 * Selects the entire stock news cache - `{ [symbol]: News }`
 */
export const selectStockNews = (state) => state.investStockNews.cache;
/**
 * Selects array of news for given symbol, guaranteed to be unique by `id` key.
 */
export const selectStockNewsForSymbol = createSelector((state, symbol) => (symbol ? state.investStockNews.cache[symbol] : undefined), (news) => {
    if (!news) {
        return [];
    }
    const result = Array.from(new Map(news.map((newsItem) => [newsItem.id, newsItem])).values());
    return result;
});
/**
 * Simply selects ordersCache from invest reducer.
 */
export const selectOrdersCache = (state) => state.invest.ordersCache;
/**
 * Selects the ids or first 4 pending orders, which are being cached by Portfolio screen
 */
export const selectPendingOrderIds = (state) => state.invest.pendingOrderIdCache;
/**
 * Selects the ids or first 4 queued orders, which are being cached by Portfolio screen
 */
export const selectQueuedOrderIds = (state) => state.invest.orderIdCache;
export const selectPendingAndQueuedOrders = createSelector([selectOrdersCache, selectPendingOrderIds, selectQueuedOrderIds], (ordersCache, pendingOrderIdCache, queuedOrderIdCache) => {
    const queuedOrders = [];
    const pendingOrders = [];
    pendingOrderIdCache.forEach((id) => {
        const order = ordersCache[id];
        if (order) {
            if (order.status === 'pending') {
                pendingOrders.push(order);
            }
        }
    });
    queuedOrderIdCache.forEach((id) => {
        const order = ordersCache[id];
        if (order) {
            if (order.status === 'queued') {
                queuedOrders.push(order);
            }
        }
    });
    return {
        queuedOrders,
        pendingOrders,
    };
});
/**
 * Selects a boolean flag indicating that `/pending-top-ups` returned some pending topup payments.
 */
export const selectPendingTopupsFlag = (state) => Boolean(debug.emulatePendingTopups || state.invest.pendingTopupsFlag);
export const selectStaticCollections = createSelector([
    (state) => state.invest,
    (state) => state.collections,
    (_state, type) => type,
], (investStore, collectionsStore, type) => {
    switch (type) {
        case 'BIGGEST_GAINERS':
            return investStore.gainers || [];
        case 'BIGGEST_LOSERS':
            return investStore.losers || [];
        case 'HIGHEST_EARNERS':
            return collectionsStore.highEarners || [];
        case 'HIGHEST_DIVIDEND_YIELD':
            return collectionsStore.dividendYield || [];
        case 'HIGHEST_MARKET_CAP':
            return collectionsStore.highestMarketCap || [];
        case 'MOST_WATCHED':
            return collectionsStore.mostWatched || [];
        case 'TOP_TRENDING':
            return investStore.topTrending || [];
        case 'MOST_POPULAR':
            return investStore.mostHeldStocks || [];
        default:
            return [];
    }
});
export const selectSetupOnboardingList = createSelector([
    (_state, type) => type,
    (state) => state.invest.topTrending,
    (state) => state.invest.mostHeldStocks,
    (state) => state.invest.mostWatchedStocks,
    (state) => state.collections.highestMarketCap,
], (type, topTrending, mostHeld, mostWatchedStocks, highestMarketCap) => {
    switch (type) {
        case 'top100':
            return highestMarketCap;
        case 'mostTraded':
            return topTrending;
        case 'mostHeld':
            return mostHeld;
        case 'mostWatched':
            return mostWatchedStocks;
        default:
            assertUnreachable(type);
            return [];
    }
});
export const selectIsInvestAccountActive = (state) => state.invest.account?.status === 'ACTIVE';
export const selectIsInvestAccountNotStarted = (state) => state.invest.account?.status === 'NOT_STARTED';
export const selectHasFxFeesFeature = (store) => store.user.user.guessedHomeCountry === 'GB';
export const selectUserFxFee = createSelector(selectUserTier, (state) => state.invest.tierFxRateMap, selectHasFxFeesFeature, (tier, tierFxRateMap, hasFxFeesFeature) => {
    if (!hasFxFeesFeature) {
        return undefined;
    }
    return tierFxRateMap?.[tier];
});
export const selectShowLowerFxFeeContent = createSelector(selectUserTier, selectHasFxFeesFeature, (tier, hasFxFeesFeature) => {
    if (tier === Tier.ultimate || !hasFxFeesFeature) {
        return false;
    }
    return true;
});
export const selectPositions = createSelector((state) => state.invest.positionsCache, (state) => state.invest.cache, (state) => state.invest.categoryIdSectorMap, (positionsCache, cache, categoryIdSectorMap) => {
    const symbols = Object.keys(positionsCache);
    filterDataByKey(symbols, positionsCache, 'amount');
    const result = [];
    symbols.forEach((symbol) => {
        const stockMeta = cache[symbol];
        const position = positionsCache[symbol];
        // the following condition will always return true since we are looping over keys of positionsCache
        if (position) {
            result.push({
                symbol,
                name: stockMeta?.name,
                iconUrl: stockMeta?.iconUrl,
                quantity: position.amount.value,
                categoryId: position.categoryId,
                sector: position.categoryId && categoryIdSectorMap
                    ? categoryIdSectorMap[position.categoryId]?.name || 'Others'
                    : 'Others',
            });
        }
    });
    return result;
});
export const selectHasCountryOfTaxResidence = (store) => store.user.user.countryOfTaxResidence;
export const selectMissingRequiredNationalityIdentifiers = (store) => store.invest.requiredNationalityIdentifiers;
export const selectShouldShowPortfolioBlock = (store) => !!store.invest.requiredNationalityIdentifiers?.length;
export const selectShouldAskForExtraInsuranceNumber = selectShouldShowPortfolioBlock;
export const selectAnnualIncome = createSelector((store) => store.user.userAdditionalInfo, (store) => store.invest.onboarding?.params, (userAdditionalInfo, onboardingParams) => {
    if (userAdditionalInfo.annualIncomeMin !== null &&
        userAdditionalInfo.annualIncomeMin !== undefined &&
        userAdditionalInfo.annualIncomeMax !== null &&
        userAdditionalInfo.annualIncomeMax !== undefined) {
        return {
            annualIncomeMin: userAdditionalInfo.annualIncomeMin,
            annualIncomeMax: userAdditionalInfo.annualIncomeMax,
        };
    }
    if (onboardingParams?.annualIncomeMin !== null &&
        onboardingParams?.annualIncomeMin !== undefined &&
        onboardingParams?.annualIncomeMax !== null &&
        onboardingParams?.annualIncomeMax !== undefined) {
        return {
            annualIncomeMin: onboardingParams.annualIncomeMin,
            annualIncomeMax: onboardingParams.annualIncomeMax,
        };
    }
    return undefined;
});
export const selectHasAnnualIncome = createSelector(selectAnnualIncome, (annualIncome) => !!annualIncome);
export const selectNetworth = createSelector((store) => store.user.userAdditionalInfo, (store) => store.invest.onboarding?.params, (userAdditionalInfo, onboardingParams) => {
    if (userAdditionalInfo.liquidNetWorthMin !== null &&
        userAdditionalInfo.liquidNetWorthMin !== undefined &&
        userAdditionalInfo.liquidNetWorthMax !== null &&
        userAdditionalInfo.liquidNetWorthMax !== undefined) {
        return {
            liquidNetWorthMin: userAdditionalInfo.liquidNetWorthMin,
            liquidNetWorthMax: userAdditionalInfo.liquidNetWorthMax,
        };
    }
    if (onboardingParams?.liquidNetWorthMin !== null &&
        onboardingParams?.liquidNetWorthMin !== undefined &&
        onboardingParams?.liquidNetWorthMax !== null &&
        onboardingParams?.liquidNetWorthMax !== undefined) {
        return {
            liquidNetWorthMin: onboardingParams.liquidNetWorthMin,
            liquidNetWorthMax: onboardingParams.liquidNetWorthMax,
        };
    }
    return undefined;
});
export const selectHasNetworth = createSelector(selectNetworth, (networth) => !!networth);
export const selectFundingSource = createSelector((store) => store.user.userAdditionalInfo, (store) => store.invest.onboarding?.params, (userAdditionalInfo, onboardingParams) => {
    if (userAdditionalInfo.fundingSource) {
        return userAdditionalInfo.fundingSource;
    }
    if (onboardingParams?.fundingSource) {
        return onboardingParams.fundingSource;
    }
    return undefined;
});
export const selectEmploymentStatus = createSelector((store) => store.user.userAdditionalInfo, (store) => store.invest.onboarding?.params, (userAdditionalInfo, onboardingParams) => {
    if (userAdditionalInfo.employmentStatus) {
        return userAdditionalInfo.employmentStatus;
    }
    if (onboardingParams?.employmentStatus) {
        return onboardingParams.employmentStatus;
    }
    return undefined;
});
export const selectTotalDayTradeCount = createSelector((state) => state.unpersistedInvestReducer.filledDayTrades, (state) => state.unpersistedInvestReducer.pendingDayTrades, (filledDayTrades, pendingDayTrades) => {
    if (filledDayTrades && pendingDayTrades) {
        return filledDayTrades.length + pendingDayTrades.length;
    }
    return undefined;
});
