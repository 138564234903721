import moment from 'moment';
import getSymbolFromCurrency from 'currency-symbol-map';
import flag from 'country-code-emoji';
import { padStart } from 'lodash';
import { Tier } from 'features/premium/entities';
export const formatDate = (date) => moment(date).calendar(undefined, {
    sameDay: '[Today at] h:mm a',
    lastDay: '[Yesterday at] h:mm a',
    lastWeek: 'dddd [at] h:mm a',
    sameElse: 'MMM Do [at] h:mm a',
});
export const formatDateForItem = (date, skipSameYearCheck) => {
    const m = moment(date);
    if (skipSameYearCheck || m.isSame(moment.utc(), 'year')) {
        return m.calendar(undefined, {
            sameDay: '[Today]',
            lastDay: '[Yesterday]',
            nextDay: '[Tomorrow]',
            lastWeek: '[Last] dddd',
            nextWeek: 'dddd',
            sameElse: 'D MMM',
        });
    }
    return m.format('D MMM YYYY');
};
const multiLine = (from, to, format) => `${from.format(format)}\n${to.format(format)}`;
const hyphenated = (from, to, format) => `${from.format(format)} - ${to.format(format)}`;
const PERIOD_FORMAT_NO_YEAR = 'D MMM';
const PERIOD_FORMAT_WITH_YEAR = "D MMM 'YY";
export const formatPeriodMoment = (period, short, excludeYear, alwaysShowBoth) => {
    const { from, to } = period;
    if (from.isSame(to, 'year') &&
        from.isSame(to, 'month') &&
        from.date() === 1 &&
        to &&
        to.date() === from.clone().endOf('month').date()) {
        return formatMonth(period.from, short);
    }
    if (to && checkCompleteQuarter(from, to)) {
        return formatQuarter(from);
    }
    if (from.dayOfYear() === 1 &&
        to &&
        to.dayOfYear() === from.clone().endOf('year').dayOfYear()) {
        return from.format('YYYY');
    }
    const sameYear = from.isSame(new Date(), 'year');
    if (to && from.isSame(to, 'day') && !alwaysShowBoth) {
        if (sameYear || excludeYear) {
            return from.format('D MMM');
        }
        return from.format("D MMM 'YY");
    }
    if (sameYear || excludeYear) {
        if (!to) {
            return from.format(PERIOD_FORMAT_NO_YEAR);
        }
        return short
            ? multiLine(from, to, PERIOD_FORMAT_NO_YEAR)
            : hyphenated(from, to, PERIOD_FORMAT_NO_YEAR);
    }
    if (!to) {
        return from.format(PERIOD_FORMAT_WITH_YEAR);
    }
    return short
        ? multiLine(from, to, PERIOD_FORMAT_WITH_YEAR)
        : hyphenated(from, to, PERIOD_FORMAT_WITH_YEAR);
};
export const formatPeriod = (period, short, excludeYear, alwaysShowBoth) => {
    const from = moment(period.from, 'YYYY-MM-DD');
    const to = period.to ? moment(period.to, 'YYYY-MM-DD') : undefined;
    return formatPeriodMoment({ from, to }, short, excludeYear, alwaysShowBoth);
};
export const formatMonth = (from, short) => {
    const date = moment(from);
    if (date.isSame(new Date(), 'year')) {
        return date.format(short ? 'MMM' : 'MMMM');
    }
    return date.format(short ? "MMM 'YY" : 'MMMM YYYY');
};
export const formatQuarter = (from) => {
    const date = moment(from);
    const year = date.year();
    const quarter = date.quarter();
    return `Q${quarter} ${year}`;
};
const checkCompleteQuarter = (startDate, endDate) => {
    const startMonth = startDate.month();
    const startDay = startDate.date();
    const endMonth = endDate.month();
    const endDay = endDate.date();
    // Check for Q1: January 1st to March 31st
    const isQ1 = startMonth === 0 && startDay === 1 && endMonth === 2 && endDay === 31;
    // Check for Q2: April 1st to June 30th
    const isQ2 = startMonth === 3 && startDay === 1 && endMonth === 5 && endDay === 30;
    // Check for Q3: July 1st to September 30th
    const isQ3 = startMonth === 6 && startDay === 1 && endMonth === 8 && endDay === 30;
    // Check for Q4: October 1st to December 31st
    const isQ4 = startMonth === 9 && startDay === 1 && endMonth === 11 && endDay === 31;
    return (isQ1 || isQ2 || isQ3 || isQ4) && startDate.year() === endDate.year();
};
export const formatRangeTextFromDates = (start, end, format) => {
    const range = 'Any day of the month';
    if (!start || !end)
        return range;
    const startDate = moment(start, format).date();
    const endDate = moment(end, format).date();
    if (startDate >= 1 && endDate <= 15)
        return 'The start of the month';
    if (startDate > 15 && endDate <= 31)
        return 'End of the month';
    if (endDate - startDate > 20)
        return 'Any day of the month';
    if (startDate >= 10 && endDate <= 20)
        return 'Middle of the month';
    return `${moment(start, format).format('Do')} to the ${moment(end, format).format('Do')} of the month`;
};
const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
];
export const getCompactNumber = (num, digits = 1) => {
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    const item = lookup
        .slice()
        .reverse()
        .find((item) => num >= item.value);
    return item
        ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
        : '0';
};
export const SCRAMBLE_CURRENCY = 'JPY';
export const formatAmount = (value, currency, fractionDigits, // null means variable fraction digits - as needed
scramble = false, removeZero) => {
    if (value === undefined || value === null) {
        return '';
    }
    const newValue = scramble ? value * Math.random() * 10 : value;
    let fraction = fractionDigits ?? (Math.round(value) === value ? 0 : 2);
    // don't try to use Number.toLocaleString - it won't work in JSI on device
    let result;
    if (fractionDigits === null) {
        result = newValue.toString();
        const decimalsDelimeterPosition = result.indexOf('.');
        if (decimalsDelimeterPosition === -1) {
            fraction = 0;
        }
        else {
            fraction = result.length - 1 - decimalsDelimeterPosition;
        }
    }
    else {
        result = newValue.toFixed(fraction);
    }
    let unsignedString = result[0] === '-' ? result.slice(1) : result;
    const firstComma = unsignedString.length - 3 - (fraction > 0 ? 1 + fraction : 0);
    if (firstComma > 0 && Math.abs(newValue) >= 1000) {
        const nChunks = Math.ceil((unsignedString.length - (fraction > 0 ? 1 + fraction : 0)) / 3);
        const chunks = [];
        for (let i = 0, pos = firstComma; i < nChunks; i += 1, pos -= 3) {
            chunks.unshift(unsignedString.substr(pos < 0 ? 0 : pos, pos < 0 ? 3 + pos : 3));
        }
        if (fraction > 0) {
            unsignedString = chunks
                .join(',')
                .concat(unsignedString.slice(-fraction - 1));
        }
        else {
            unsignedString = chunks.join(',');
        }
        result = result[0] === '-' ? `-${unsignedString}` : unsignedString;
    }
    if (currency) {
        const currencySymbol = getSymbolFromCurrency(scramble ? SCRAMBLE_CURRENCY : currency) ||
            currency;
        if (result[0] === '-') {
            result = `-${currencySymbol}${result.slice(1)}`;
        }
        else if (currencySymbol) {
            result = `${currencySymbol}${result}`;
        }
        else {
            result = `${currency}${result}`;
        }
    }
    if (removeZero)
        return result.replace(/(\.\d*[1-9])0+$|\.0+$/, '$1');
    return result;
};
const formatPositiveAmount = (amount) => {
    'worklet';
    let result = amount.toFixed(2);
    const firstComma = result.length - 6;
    if (firstComma > 0) {
        const nChunks = Math.ceil((result.length - 3) / 3);
        const chunks = [];
        for (let i = 0, pos = firstComma; i < nChunks; i += 1, pos -= 3) {
            chunks.unshift(result.substr(pos < 0 ? 0 : pos, pos < 0 ? 3 + pos : 3));
        }
        result = chunks.join(',').concat(result.slice(-3));
    }
    return result;
};
export const formatAmountWorklet = (amount, currencySymbol, isScrambled, scrambleFactor = 1) => {
    'worklet';
    const finalCurrencySymbol = isScrambled ? '¥' : currencySymbol;
    if (amount < 0) {
        return `-${finalCurrencySymbol}${formatPositiveAmount(Math.abs(amount * scrambleFactor))}`;
    }
    return `${finalCurrencySymbol}${formatPositiveAmount(amount * scrambleFactor)}`;
};
export const formatPhoneNumber = (rawText) => {
    const text = rawText.replace(/[\D]/g, '');
    let phone = text;
    const isSingleDigitCountryCode = text.substr(0, 1) === '1' || text.substr(0, 1) === '7'
        ? text.substr(0, 1)
        : null;
    if (text.length < 2) {
        if (rawText === '+') {
            phone = `+`;
        }
        else {
            phone = `+${text}`;
        }
    }
    else if (text.length < 5) {
        if (isSingleDigitCountryCode) {
            phone = `+${isSingleDigitCountryCode}\xa0${text.substr(1, 3)}`;
        }
        else {
            phone = `+${text.substr(0, 2)}\xa0${text.substr(2, 4)}`;
        }
    }
    else if (text.length < 8) {
        if (isSingleDigitCountryCode) {
            phone = `+${isSingleDigitCountryCode}\xa0${text.substr(1, 3)}\xa0${text.substr(4, 3)}`;
        }
        else if (text.length <= 6) {
            phone = `+${text.substr(0, 2)}\xa0${text.substr(2, 4)}`;
        }
        else {
            // text.length === 7
            phone = `+${text.substr(0, 2)}\xa0${text.substr(2, 4)}\xa0${text.substr(6, 1)}`;
        }
    }
    else if (text.length < 10) {
        if (isSingleDigitCountryCode) {
            phone = `+${isSingleDigitCountryCode}\xa0${text.substr(1, 3)}\xa0${text.substr(4, 3)}\xa0${text.substr(7, 2)}`;
        }
        else {
            phone = `+${text.substr(0, 2)}\xa0${text.substr(2, 4)}\xa0${text.substr(6, 3)}`;
        }
    }
    else if (text.length < 12) {
        if (isSingleDigitCountryCode) {
            phone = `+${isSingleDigitCountryCode}\xa0${text.substr(1, 3)}\xa0${text.substr(4, 3)}\xa0${text.substr(7, 2)}\xa0${text.substr(9, 2)}`;
        }
        else {
            phone = `+${text.substr(0, 2)}\xa0${text.substr(2, 4)}\xa0${text.substr(6, 3)}\xa0${text.substr(9, 2)}`;
        }
    }
    else if (isSingleDigitCountryCode) {
        phone = text;
    }
    else {
        phone = `+${text.substr(0, 2)}\xa0${text.substr(2, 4)}\xa0${text.substr(6, 3)}\xa0${text.substr(9, 3)}`;
    }
    return phone;
};
export const splitAmount = (amount, divider) => {
    if (divider < 2)
        return [amount, 0];
    const inCents = Math.round(amount * 100);
    const reminder = inCents % divider;
    const item = (inCents - reminder) / divider;
    return [(item + reminder) / 100, item / 100];
};
export const DEFAULT_MAX_AMOUNT = 10 ** 9; // for MoneyInput, 1 Billion is not supported by backend
export function formatAmountText(text, givenMaxAmount) {
    /* eslint-enable no-redeclare */
    const maxAmount = givenMaxAmount === undefined
        ? DEFAULT_MAX_AMOUNT
        : givenMaxAmount ?? 10 ** 20; // null effectively disables max amount checks
    let maxLength;
    // let's ensure text only contains numbers and 1 dot symbol
    let fixedText = text.replace(/,/g, '.').replace(/[^\d.]/g, ''); // only digits and dots
    if (fixedText === '.') {
        // edge case
        fixedText = '0.';
    }
    else if (fixedText === '') {
        fixedText = '0';
    }
    const firstDotIndex = fixedText.indexOf('.');
    if (firstDotIndex !== -1) {
        // there is a dot, make sure it's the only dot
        const beforeDot = fixedText.substr(0, firstDotIndex);
        const afterDot = fixedText
            .substr(firstDotIndex + 1)
            .replace(/[^\d]/g, '')
            .substr(0, 2); // only 2 digits after dot max
        fixedText = `${beforeDot}.${afterDot}`;
    }
    // now we have fixedText which may come as 01000.
    // to ensure there are no extra leading zeros, we take parseFloat and add a dot if it was last
    if (fixedText.substr(-1) === '.') {
        // there is a dot at the end, so add it back
        fixedText = `${parseFloat(fixedText).toString()}.`;
    }
    else if (fixedText.substr(-2) === '.0') {
        fixedText = `${parseFloat(fixedText).toString()}.0`;
    }
    else if (fixedText.substr(-3) === '.00') {
        fixedText = `${parseFloat(fixedText).toString()}.00`; // keep it with cents
    }
    else if (fixedText.match(/\.\d0$/)) {
        // like 0.60
        fixedText = `${parseFloat(fixedText).toString()}0`; // keep that 0 at the end
    }
    else {
        fixedText = parseFloat(fixedText).toString();
    }
    // amount is just a parsed float of fixedText
    const newAmount = parseFloat(fixedText);
    if (newAmount >= maxAmount) {
        // user entered amount that is greater than maxAmount - shake, vibrate and warn
        return undefined;
    }
    const amount = newAmount;
    const fixedTextResult = fixedText;
    // determine if entering more text can lead to exceeding maxAmount
    if (firstDotIndex !== -1) {
        // if user has started to enter cents, let him finish
        maxLength = firstDotIndex + 3;
    }
    else {
        maxLength = maxAmount.toString().length + 3;
    }
    let pounds = firstDotIndex === -1 ? fixedText : fixedText.substr(0, firstDotIndex);
    // displayAmount only differs by comma symbols
    if (pounds.length > 12) {
        pounds = pounds.replace(/(.*)(\d{3})(\d{3})(\d{3})(\d{3})$/, '$1,$2,$3,$4,$5');
    }
    else if (pounds.length > 9) {
        pounds = pounds.replace(/(.*)(\d{3})(\d{3})(\d{3})$/, '$1,$2,$3,$4');
    }
    else if (pounds.length > 6) {
        pounds = pounds.replace(/(.*)(\d{3})(\d{3})$/, '$1,$2,$3');
    }
    else if (pounds.length > 3) {
        pounds = pounds.replace(/(.*)(\d{3})$/, '$1,$2');
    }
    const displayAmount = firstDotIndex === -1
        ? pounds
        : `${pounds}${fixedText.substr(firstDotIndex)}`;
    return {
        amount,
        fixedText: fixedTextResult,
        displayAmount,
        maxLength,
    };
}
export function getScale(displayAmount) {
    if (displayAmount.length <= 7) {
        return 1;
    }
    return 7.0 / displayAmount.length;
}
export function getInitialScale(maxAmount, initialAmountText) {
    if (!initialAmountText) {
        return 1;
    }
    const values = formatAmountText(initialAmountText, maxAmount);
    if (!values) {
        return 1;
    }
    return getScale(values.displayAmount);
}
export function getNumberOfDaysToDate(date) {
    const days = date.diff(moment(), 'days');
    switch (true) {
        case days === 1:
            return '1 day';
        case days < 1:
            return '0 days';
        default:
            return `${days} days`;
    }
}
export function isoCodeToEmojiFlag(iso2) {
    // Netherlands Antilles does not have an emoji flag
    if (iso2 === 'AN')
        return flag('NL');
    return flag(iso2);
}
export const formatDateForSectionHeader = (date) => {
    const m = moment(date);
    if (m.isSame(moment.utc(), 'year')) {
        return m.format('MMMM');
    }
    return m.format('MMMM YYYY');
};
export const formatDateForSectionHeaderWithDate = (date) => {
    const m = moment(date);
    if (m.isSame(moment.utc(), 'year')) {
        return m.format('dddd, D MMMM');
    }
    return m.format('dddd, D MMMM YYYY');
};
export const createActivitySections = (items, key) => {
    if (!items.length)
        return [];
    if (!items[0][key])
        return [];
    const grouped = [];
    const timestampKey = items[0][key];
    if (items[0]) {
        const m = moment(timestampKey);
        grouped.push({
            title: formatDateForSectionHeader(timestampKey),
            month: m.month(),
            year: m.year(),
            data: [{ ...items[0], isFirstInSection: true }],
        });
    }
    for (let i = 1; i < items.length; i++) {
        if (items[i]) {
            const timestampKeyForI = items[i][key];
            const title = formatDateForSectionHeader(timestampKeyForI);
            if (title === grouped[grouped.length - 1].title) {
                grouped[grouped.length - 1].data.push({
                    ...items[i],
                    isFirstInSection: undefined,
                    isLastInSection: undefined,
                });
            }
            else {
                const m = moment(timestampKeyForI);
                grouped.push({
                    title,
                    month: m.month(),
                    year: m.year(),
                    data: [{ ...items[i], isFirstInSection: true }],
                });
            }
        }
    }
    grouped.forEach((group) => {
        const itemsLen = group.data.length;
        // eslint-disable-next-line no-param-reassign
        group.data[itemsLen - 1].isLastInSection = true;
    });
    return grouped;
};
const emptyArr = [];
export const createCardViewList = (items) => {
    const itemsLength = items?.length;
    if (itemsLength) {
        // @ts-ignore
        const finalResults = [...items];
        const newFirstItem = {
            ...items[0],
            isFirstItem: true,
        };
        finalResults[0] = newFirstItem;
        const newLastItem = {
            ...finalResults[itemsLength - 1],
            isLastItem: true,
        };
        finalResults[itemsLength - 1] = newLastItem;
        return finalResults;
    }
    return emptyArr;
};
export const removeLineBreaksAndTabs = (title) => title.replace(/(\t\n|\n|\t)/gm, '').trim();
export const formatAddress = ({ streetName, townOrCity, county, buildingName, buildingNumber, subBuildingName, } = {}) => [
    subBuildingName || '',
    buildingName || '',
    streetName
        ? `${!!buildingNumber && buildingNumber.length ? `${buildingNumber} ` : ''}${streetName}`
        : streetName,
    townOrCity,
    county,
]
    .filter(Boolean)
    .join(', ');
export const formatUnsignedInteger = (number) => {
    if (!number) {
        return '0';
    }
    const numberStr = String(number);
    let finalString = '';
    for (let i = 0; i < numberStr.length; i++) {
        if (numberStr.length - i !== numberStr.length &&
            (numberStr.length - i) % 3 === 0) {
            finalString += `,${numberStr[i]}`;
        }
        else {
            finalString += numberStr[i];
        }
    }
    return finalString;
};
export const formatPlural = (singularWord, pluralWord, count) => {
    if (count === 1) {
        return singularWord;
    }
    return pluralWord;
};
/**
 * Formats a single digit number to two
 */
export const formatDoubleDigit = (number) => padStart(number, 2, '0');
export const formatStringList = (array) => {
    if (!array || array.length === 0) {
        return '';
    }
    if (array.length === 1) {
        return array[0];
    }
    if (array.length === 2) {
        return array.join(' and ');
    }
    return array
        .slice(0, array.length - 1)
        .join(', ')
        .concat(', and ')
        .concat(array[array.length - 1]);
};
export const getOrdinalSuffix = (number) => {
    if (number > 3 && number < 21)
        return 'th';
    switch (number % 10) {
        case 1:
            return 'st';
        case 2:
            return 'nd';
        case 3:
            return 'rd';
        default:
            return 'th';
    }
};
export const capitalizeFirstCharacter = (word) => {
    if (!word)
        return '';
    const firstLetter = word[0].toUpperCase();
    const restOfTheLetters = word.slice(1, word.length).toLowerCase();
    return firstLetter + restOfTheLetters;
};
export const deduplicateWithKey = (array, key) => {
    const reduceObjects = array.reduce((prev, curr) => ({
        ...prev,
        [curr[key]]: curr,
    }), {});
    return Object.values(reduceObjects);
};
export const getTierText = (tier) => {
    switch (tier) {
        case Tier.ultimate:
            return 'Emma Ultimate';
        case Tier.pro:
            return 'Emma Pro';
        case Tier.plus:
            return 'Emma Plus';
        default:
            return 'Free Plan';
    }
};
/**
 * Calculates the percentage of a part relative to a total and formats it as a string. All trailing 0s are removed.
 *
 * @param {number} part - The part value to calculate the percentage for.
 * @param {number} total - The total value against which the percentage is calculated.
 * @param {number} [fractionDigits=2] - The number of digits to appear after the decimal point. Default is 2.
 * @returns {string} - The formatted percentage string with trailing zeros removed.
 *
 * @example
 * // returns '50%'
 * calculateAndFormatPercentage(1, 2);
 *
 * @example
 * // returns '25%'
 * calculateAndFormatPercentage(50, 200);
 *
 * @example
 * // returns '33.33%'
 * calculateAndFormatPercentage(1, 3);
 *
 * @example
 * // returns '100%'
 * calculateAndFormatPercentage(7, 7, 3);
 *
 * @example
 * // returns '0%'
 * calculateAndFormatPercentage(5, 0);
 */
export const calculateAndFormatPercentage = (part, total, fractionDigits = 2) => {
    if (total === 0) {
        return '0%';
    }
    const percentage = (part / total) * 100;
    const formattedPercentage = percentage
        .toFixed(fractionDigits)
        .replace(/\.?0+$/, '')
        .replace(/(\.\d*[1-9])0+$/, '$1');
    return `${formattedPercentage}%`;
};
export const formatSortCodeAndAccountNumber = (sortCodeStr, accountNumberStr, scrambled) => {
    let sortCode = sortCodeStr;
    let accountNumber = accountNumberStr;
    if (scrambled) {
        sortCode = '00-00-00';
        accountNumber = '12345678';
    }
    else if (sortCode) {
        sortCode = sortCode.replace(/(\d\d)(\d\d)(\d\d)/, '$1-$2-$3');
    }
    return `${sortCode || ''}${accountNumber ? `  ${accountNumber}` : ''}`;
};
